import { Stack } from '@mui/material'

import { Checkbox, Typography } from '@lib/ui'

import { type ICKOCanSaveCardProps } from './CKOCanSaveCard.types'

const CKOCanSaveCard = (props: ICKOCanSaveCardProps) => {
  const { value, onChange } = props

  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      gap="8px"
    >
      <Checkbox checked={value} onChange={onChange} />

      <Typography color="var(--Neutral3)" fontVariant="body-3" weight="regular">
        Save my card details for future purchases
      </Typography>
    </Stack>
  )
}

export default CKOCanSaveCard
