import { useEffect, useMemo } from 'react'

import { isNil } from 'lodash-es'

import requestV2 from '@services/api'

import { useQuery } from '@tanstack/react-query'

import { QueryClientKeysEnum } from '@enums'

import { getServerErrorMessage } from '@utils/getServerError'
import { useStores } from '@utils'

export function useSavedCards() {
  const { notificationStore } = useStores()

  const {
    data: response = null,
    isLoading = false,
    error = null
  } = useQuery({
    queryKey: [QueryClientKeysEnum.GET_SAVED_CARDS],
    queryFn: () => requestV2(`/users/saved-cards?limit=${100}`, 'GET'),
    select: ({ data }) => data,
    retry: false
  })

  const cards = useMemo(() => {
    if (isNil(response)) {
      return []
    }

    const { data } = response

    return data
  }, [response])

  useEffect(() => {
    if (!isNil(error)) {
      const message = getServerErrorMessage({
        error,
        defaultMessage: 'Saved cards loading was unsuccessful'
      })

      notificationStore.notify({
        message,
        type: 'error'
      })
    }
  }, [error])

  return {
    isLoadingSavedCards: isLoading,
    cards
  }
}
