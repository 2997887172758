import { getAuth, signOut } from 'firebase/auth'

import nookies from 'nookies'

import { action, configure, makeObservable, observable } from 'mobx'

import * as Sentry from '@sentry/nextjs'

import * as FBPixel from '@utils/FBPixel'
import * as SCPixel from '@utils/SCPixel'
import { getFirebaseApp } from '@utils/Firebase'
import { setLogContext } from '@utils/errorReporting'
import {
  FlutterChannelMessageActionEnum,
  sendFlutterChannelMessage
} from '@utils/channels/flutterChannel'
import { getProfileFullName } from '@utils/getProfileFullName'

import hotJarUserIdentificationEvent from '@helpers/hotJarLoad'
import { isBrowser } from '@helpers/isBrowser'

import {
  UsersService,
  type UserProfile,
  type UserBillingAddressDTO
} from '@elitecompetitions/client-api'

import { ticketsStoreFactory } from './Tickets'

configure({
  enforceActions: 'observed'
})

export class AuthStore {
  isLoggedIn = false

  profile: UserProfile | null = null

  userId = ''

  token: string | undefined = undefined

  constructor() {
    makeObservable(this, {
      isLoggedIn: observable,
      profile: observable,
      userId: observable,

      setUserId: action,
      setLogin: action,
      setProfile: action,
      login: action,
      logout: action,
      updateUserDetails: action
    })

    if (typeof window === 'undefined') return

    this.hydrateStore()
  }

  hydrateStore() {
    getAuth(getFirebaseApp()).onIdTokenChanged(async user => {
      if (!user) {
        this.setToken(undefined)

        nookies.destroy(undefined, 'token')
        nookies.destroy(undefined, 'refreshToken')
      } else {
        const token = await user.getIdToken()

        this.setToken(token)

        nookies.set(undefined, 'token', token, {
          path: '/',
          maxAge: 30 * 24 * 60 * 60
        })
        nookies.set(undefined, 'refreshToken', user.refreshToken, {
          path: '/',
          maxAge: 30 * 24 * 60 * 60
        })
      }
    })
  }

  setToken(token: string | undefined) {
    this.token = token
  }

  async getToken() {
    if (!isBrowser()) {
      return this.token
    }

    const firebaseToken =
      await getAuth(getFirebaseApp()).currentUser?.getIdToken()

    if (firebaseToken) {
      return firebaseToken
    }

    return this.token
  }

  setUserId(userId) {
    this.userId = userId
  }

  setLogin(login) {
    this.isLoggedIn = login
  }

  async setProfile(profile: UserProfile | null) {
    this.profile = profile

    const advancedMatching =
      await FBPixel.getFBPixelAdvancedMatchingObject(profile)
    FBPixel.init(advancedMatching)
    SCPixel.init(profile)

    Sentry.getGlobalScope().setUser(
      profile
        ? {
            id: profile.userId,
            username: getProfileFullName(profile),
            email: profile.email
          }
        : null
    )
    setLogContext(profile)
  }

  async login() {
    try {
      const userDetails = await UsersService.getProfile()

      this.setUserId(userDetails.userId)
      this.setLogin(true)

      sendFlutterChannelMessage<{ email: string }>({
        action: FlutterChannelMessageActionEnum.LOGIN,
        data: {
          email: userDetails.email
        }
      })

      await this.setProfile(userDetails)

      hotJarUserIdentificationEvent({
        isLoggedIn: true,
        profile: userDetails
      })

      const ticketsStore = ticketsStoreFactory()

      await ticketsStore.attachUserReservedTicketListeners()
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  async logout() {
    try {
      await signOut(getAuth(getFirebaseApp()))

      this.setUserId('')
      await this.setProfile(null)
      this.setLogin(false)

      nookies.destroy(undefined, 'token')
      nookies.destroy(undefined, 'refreshToken')

      sendFlutterChannelMessage({
        action: FlutterChannelMessageActionEnum.LOGOUT
      })

      const ticketsStore = ticketsStoreFactory()

      await ticketsStore.removeUserReservedTicketListeners()
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  updateUserDetails = async (details: {
    billingAddresses: UserBillingAddressDTO[]
  }) => {
    const userDetails = await UsersService.updateProfile({
      requestBody: details
    })

    await this.setProfile(userDetails)
  }
}

let authStore: AuthStore

export function authStoreFactory(initialStore?: AuthStore | null) {
  if (!process.browser) {
    authStore = new AuthStore()
  }

  if (process.browser && !authStore) {
    authStore = new AuthStore()

    if (initialStore?.token) {
      authStore.setToken(initialStore.token)
    }

    if (initialStore?.profile) {
      authStore.setProfile(initialStore.profile)
      authStore.setUserId(initialStore.profile.userId)
      authStore.setLogin(true)

      ticketsStoreFactory().attachUserReservedTicketListeners()
    }
  }

  return authStore
}

export default authStore
