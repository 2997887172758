import { useRouter } from 'next/router'

import { toString, isString } from 'lodash-es'

import { useCallback, useEffect, useMemo } from 'react'

import * as Sentry from '@sentry/nextjs'

import { isANDROID } from '@utils'

import {
  FlutterChannelMessageActionEnum,
  sendFlutterChannelMessage
} from '@utils/channels/flutterChannel'

import config from '@services/config'

import { type ICheckoutGooglePayProps } from './CheckoutGooglePay.types'
import { StyledGooglePayButton } from './CheckoutGooglePay.styled'
import { basePaymentRequestConfiguration } from './CheckoutGooglePay.data'

const CheckoutGooglePay = (props: ICheckoutGooglePayProps) => {
  const { finalPrice, onProcessGooglePayPayment, disabled = false } = props

  const router = useRouter()

  const paymentRequest = useMemo<google.payments.api.PaymentDataRequest>(() => {
    return {
      ...basePaymentRequestConfiguration,
      transactionInfo: {
        ...basePaymentRequestConfiguration.transactionInfo,
        totalPrice: toString(finalPrice) // Must be a string!
      }
    }
  }, [finalPrice])

  const handleLoadPaymentData = useCallback(
    async (paymentData: google.payments.api.PaymentData) => {
      const { paymentMethodData } = paymentData
      const { tokenizationData } = paymentMethodData
      const { token } = tokenizationData
      const { signature, signedMessage } = JSON.parse(token)

      const googlePayToken: IGooglePayToken = {
        protocolVersion: 'ECv1',
        signature: signature,
        signedMessage: signedMessage
      }

      const { pathname } = await onProcessGooglePayPayment(googlePayToken)

      await router.push(pathname)
    },
    [router, onProcessGooglePayPayment]
  )

  const handleBtnClick = useCallback(
    (event: Event) => {
      if (isANDROID()) {
        event.preventDefault()

        sendFlutterChannelMessage<{
          total: string
        }>({
          action: FlutterChannelMessageActionEnum.GOOGLE_PAY_REQUEST,
          data: {
            total: toString(finalPrice) // Must be a string!
          }
        })
      }
    },
    [finalPrice]
  )

  const handleMessage = useCallback(
    async (event: MessageEvent) => {
      const { origin, data } = event

      if (origin !== config.app.url) {
        return
      }

      if (isString(data)) {
        try {
          const parsedMessageData: {
            action: string
            data: google.payments.api.PaymentData
          } = JSON.parse(data)

          if (parsedMessageData.action === 'google-pay-result') {
            await handleLoadPaymentData(parsedMessageData.data)
          }
        } catch (error) {
          console.error('Error parsing JSON payment data:', error)
        }
      }
    },
    [handleLoadPaymentData]
  )

  useEffect(() => {
    window.addEventListener('message', handleMessage)

    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [handleMessage])

  const handleError = useCallback(
    (error: Error | google.payments.api.PaymentsError) => {
      Sentry.captureException(error)
    },
    []
  )

  return (
    <StyledGooglePayButton
      onClick={handleBtnClick}
      buttonSizeMode="fill"
      buttonType="short"
      style={{
        width: '100%'
      }}
      buttonColor="white"
      environment={config.googlePay.env}
      paymentRequest={paymentRequest}
      isDisabled={disabled}
      onLoadPaymentData={handleLoadPaymentData}
      onError={handleError}
    />
  )
}

export default CheckoutGooglePay
