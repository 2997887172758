import { makeObservable, observable, action } from 'mobx'

export class LuckyDipStore {
  value: number = 0

  constructor() {
    makeObservable(this, {
      value: observable,

      setValue: action
    })

    this.setValue = this.setValue.bind(this)
  }

  setValue(value: number) {
    this.value = value
  }
}

let luckyDipStore: LuckyDipStore

export function luckyDipStoreFactory() {
  if (!process.browser) {
    luckyDipStore = new LuckyDipStore()
  }

  if (process.browser && !luckyDipStore) {
    luckyDipStore = new LuckyDipStore()
  }

  return luckyDipStore
}

export default luckyDipStore
