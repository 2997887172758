import { useCallback } from 'react'

import canvasConfetti from 'canvas-confetti'

import { useMediaQuery, useTheme } from '@mui/material'

export function useConfetti() {
  const theme = useTheme()

  const isXs = useMediaQuery(theme.breakpoints.down('sm'))

  const showSpinToWinConfetti = useCallback(() => {
    const scalar = isXs ? 5 : 10

    const fourLeafClover = canvasConfetti.shapeFromText({
      text: '🍀',
      scalar
    })
    const slotMachine = canvasConfetti.shapeFromText({
      text: '🎰',
      scalar
    })
    const wrappedGift = canvasConfetti.shapeFromText({
      text: '🎁',
      scalar
    })
    const moneyBag = canvasConfetti.shapeFromText({
      text: '💰',
      scalar
    })
    const euroBanknote = canvasConfetti.shapeFromText({
      text: '💶',
      scalar
    })
    const poundBanknote = canvasConfetti.shapeFromText({
      text: '💷',
      scalar
    })
    const moneyMouthFace = canvasConfetti.shapeFromText({
      text: '🤑',
      scalar
    })
    const dollarBanknote = canvasConfetti.shapeFromText({
      text: '💵',
      scalar
    })
    const yenBanknote = canvasConfetti.shapeFromText({
      text: '💴',
      scalar
    })
    const gemStone = canvasConfetti.shapeFromText({
      text: '💎',
      scalar
    })
    const gameDie = canvasConfetti.shapeFromText({
      text: '🎲',
      scalar
    })
    const coin = canvasConfetti.shapeFromText({
      text: '🪙',
      scalar
    })

    const defaults = {
      spread: 360,
      ticks: 60,
      gravity: 0,
      decay: 0.96,
      startVelocity: 20,
      shapes: [
        // coin,
        // moneyMouthFace,
        // gemStone,
        // gameDie,
        yenBanknote,
        dollarBanknote,
        poundBanknote,
        euroBanknote,
        // fourLeafClover,
        // slotMachine,
        wrappedGift,
        moneyBag
      ],
      scalar
    }

    canvasConfetti({
      ...defaults,
      particleCount: 50
    })
  }, [isXs])

  const showVictoryConfetti = useCallback(() => {
    const end = Date.now() + 2000

    ;(function frame() {
      canvasConfetti({
        zIndex: 9999,
        particleCount: 2,
        angle: 60,
        spread: 55,
        origin: {
          x: 0
        }
      })

      canvasConfetti({
        zIndex: 9999,
        particleCount: 2,
        angle: 120,
        spread: 55,
        origin: {
          x: 1
        }
      })

      if (Date.now() < end) {
        requestAnimationFrame(frame)
      }
    })()
  }, [])

  const showLuckyDipConfetti = useCallback(async () => {
    await canvasConfetti({
      particleCount: 100,
      spread: 70,
      origin: {
        y: isXs ? 1 : 0.6
      },
      ticks: 100,
      disableForReducedMotion: true,
      zIndex: 9999
    })
  }, [isXs])

  return {
    showSpinToWinConfetti,
    showLuckyDipConfetti,
    showVictoryConfetti
  }
}
