import { enableStaticRendering } from 'mobx-react'

import { AuthStore, authStoreFactory } from './Auth'
import { LuckyDipStore, luckyDipStoreFactory } from './LuckyDip'
import { BasketStore, basketStoreFactory } from './Basket'
import { NotificationStore, notificationStoreFactory } from './Notification'
import { TicketsStore, ticketsStoreFactory } from './Tickets'
import { DefaultCartStore, defaultCartStoreFactory } from './DefaultCart'
import { WheelCartStore, wheelCartStoreFactory } from './WheelCart'
import { CheckoutStore, checkoutStoreFactory } from './Checkout'

const isServer = typeof window === 'undefined'

enableStaticRendering(isServer)

export type ComposedStore = {
  authStore: AuthStore
  luckyDipStore: LuckyDipStore
  basketStore: BasketStore
  notificationStore: NotificationStore
  ticketsStore: TicketsStore
  defaultCartStore: DefaultCartStore
  wheelCartStore: WheelCartStore
  checkoutStore: CheckoutStore
}

let store: ComposedStore | null = null

export default function initializeStore(
  initialStore: ComposedStore | null = null
) {
  if (!isServer && store !== null) {
    return store
  }

  const _store: ComposedStore = {
    authStore: authStoreFactory(initialStore?.authStore || null),
    notificationStore: notificationStoreFactory(),
    luckyDipStore: luckyDipStoreFactory(),
    basketStore: basketStoreFactory(),
    ticketsStore: ticketsStoreFactory(),
    defaultCartStore: defaultCartStoreFactory(),
    wheelCartStore: wheelCartStoreFactory(),
    checkoutStore: checkoutStoreFactory()
  }

  if (isServer) {
    return _store
  }

  if (store === null) {
    store = _store
  }

  return store
}
