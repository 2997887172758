import config from '@services/config'

import { CurrencyTypeEnum } from '@enums'

export const basePaymentRequestConfiguration: google.payments.api.PaymentDataRequest =
  {
    apiVersion: 2,
    apiVersionMinor: 0,
    allowedPaymentMethods: [
      {
        type: 'CARD',
        parameters: {
          allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
          allowedCardNetworks: [
            'AMEX',
            'DISCOVER',
            'INTERAC',
            'JCB',
            'MASTERCARD',
            'VISA'
          ]
        },
        tokenizationSpecification: {
          type: 'PAYMENT_GATEWAY',
          parameters: {
            gateway: 'checkoutltd',
            gatewayMerchantId: config.cko.publicKey
          }
        }
      }
    ],
    merchantInfo: {
      merchantId: config.googlePay.merchantId,
      merchantName: config.googlePay.merchantName
    },
    transactionInfo: {
      totalPriceStatus: 'FINAL',
      totalPriceLabel: 'Total',
      totalPrice: '0',
      currencyCode: CurrencyTypeEnum.GBP,
      countryCode: 'GB'
    }
  }
