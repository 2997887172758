import { Box, css, Stack, styled } from '@mui/material'

export const StyledAkaButton = styled('span')`
  margin: 16px 12px;
  width: calc(100% - 24px);
  display: none;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  user-select: none;
  color: var(--Neutral1);
  background-color: var(--Primary);
  max-height: 40px;
  border-radius: 8px;
  padding: 10px 24px;

  ${({
    theme: {
      breakpoints: {
        values: { sm }
      }
    }
  }) => {
    return css`
      @media (max-width: ${sm}px) {
        display: inline-flex;
      }
    `
  }}
`

export const StyledBadgeContainer = styled(Box, {
  shouldForwardProp: propName => propName !== 'isJackpot'
})<{
  isJackpot?: boolean
}>`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  padding-bottom: 16px;
  padding-right: 16px;
  bottom: 0;
  gap: 8px;
  left: 0;

  ${({
    theme: {
      breakpoints: {
        values: { sm }
      }
    }
  }) => {
    return css`
      @media (max-width: ${sm}px) {
        padding-right: 8px;
        padding-bottom: 8px;
        bottom: 70px;
        gap: 4px;
      }
    `
  }}

  ${({ isJackpot = false }) => {
    if (isJackpot) {
      return css`
        top: 1% !important;
        bottom: unset !important;
        left: -15px !important;
      `
    }
  }}
`

export const StyledBadgeStack = styled(Stack)`
  gap: 8px;

  ${({
    theme: {
      breakpoints: {
        values: { sm }
      }
    }
  }) => {
    return css`
      @media (max-width: ${sm}px) {
        gap: 4px;
      }
    `
  }}
`

export const StyledJackpotAmount = styled('div')`
  position: absolute;
  font-size: clamp(20px, 4vw, 58px);
  top: 43%;
  width: 100%;

  ${({
    theme: {
      breakpoints: {
        values: { sm }
      }
    }
  }) => {
    return css`
      @media (max-width: ${sm}px) {
        top: 39%;
      }
    `
  }}
`

export const StyledCharityJackpotAmount = styled('div')`
  position: absolute;
  font-size: clamp(12px, 3vw, 36px);
  bottom: 19%;
  width: 100%;

  ${({
    theme: {
      breakpoints: {
        values: { sm }
      }
    }
  }) => {
    return css`
      @media (max-width: ${sm}px) {
        bottom: 17%;
      }
    `
  }}
`
