import { Box } from '@mui/material'

import { Typography } from '@lib/ui'

import { type ICartTimerProps } from './CartTimer.types'

const CartTimer = (props: ICartTimerProps) => {
  const { timeRemainingInMin } = props

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      width="100%"
      bgcolor="var(--PrimaryVariant)"
      padding="11px"
      borderRadius="8px"
      gap="4px"
    >
      <Typography
        textAlign="center"
        fontVariant="body-2"
        weight="regular"
        color="var(--Neutral1)"
      >
        <i className="fa-regular fa-warning" />
      </Typography>

      <Typography
        textAlign="center"
        fontVariant="body-2"
        weight="regular"
        color="var(--Neutral1)"
      >
        {`Cart will be auto cleared in ${timeRemainingInMin} mins`}
      </Typography>
    </Box>
  )
}

export default CartTimer
