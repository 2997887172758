import type {
  CoverflowEffectOptions,
  PaginationOptions,
  SwiperOptions
} from 'swiper/types'
import { EffectCoverflow, Pagination } from 'swiper/modules'

export const defaultCoverflowEffect: CoverflowEffectOptions = {
  rotate: 60,
  stretch: 0,
  depth: 200,
  modifier: 1,
  slideShadows: true
}

export const defaultPagination: PaginationOptions = {
  clickable: true,
  dynamicBullets: true
}

export const defaultBreakpoints: {
  [width: number]: SwiperOptions
  [ratio: string]: SwiperOptions
} = {
  640: {
    spaceBetween: 20
  },
  768: {
    spaceBetween: 20
  },
  1024: {
    slidesPerView: 1.3,
    spaceBetween: 30
  },
  1200: {
    slidesPerView: 1.5,
    spaceBetween: 80
  }
}

export const defaultModules = [Pagination, EffectCoverflow]
