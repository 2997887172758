;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"1.0.1"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "/cdnbucketassets/1993";import * as Sentry from '@sentry/nextjs'

import config from '@services/config'

import { AppEnvTypeEnum } from '@enums'

if (config.sentry.isAvailable) {
  Sentry.init({
    dsn: config.sentry.dsn,
    release: config.sentry.release,
    environment: config.app.env || AppEnvTypeEnum.STAGING,
    ignoreErrors: [
      /Network Error/,

      // region Firebase

      /auth\/network-request-failed/

      // endregion
    ]
  })
}
