import { useCallback } from 'react'

import { Button } from '@lib/ui'

import { type ICartCheckoutButtonTextProps } from './CartCheckoutButton.types'

const CartCheckoutButton = (props: ICartCheckoutButtonTextProps) => {
  const {
    finalPrice,
    initialText = 'Checkout',
    isLoading = false,
    hasTNCConsented = false,
    disabled = false,
    onCheckout
  } = props

  const renderCheckoutButtonText = useCallback(() => {
    if (isLoading) {
      return (
        <>
          <i className="fa fa-spinner fa-spin" /> Processing...
        </>
      )
    }

    return finalPrice === 0 ? 'Pay £0' : initialText
  }, [finalPrice, isLoading, initialText])

  return (
    <Button
      width="100%"
      type="button"
      size="large"
      variant="primary"
      disabled={!hasTNCConsented || isLoading || disabled}
      onClick={onCheckout}
    >
      {renderCheckoutButtonText()}
    </Button>
  )
}

export default CartCheckoutButton
