import { isNil } from 'lodash-es'

import config from '@services/config'

import { hashData } from '@helpers'
import { isBrowser } from '@helpers/isBrowser'

import { type UserProfile } from '@elitecompetitions/client-api'

export interface IAdvancedMatchingObject {
  /**
   * Hashed email address
   */
  em: string
  /**
   * Hashed first name
   */
  fn: string
  /**
   * Hashed phone number
   */
  ph: string
  /**
   * Hashed date of birth
   */
  db: string
  /**
   * Hashed city (town)
   */
  ct: string
  /**
   * Hashed postal code
   */
  zip: string
  /**
   * Hashed last name
   */
  ln: string
  /**
   * The user's external ID
   */
  external_id: string
}

export enum FBPixelEventEnum {
  INIT = 'init',
  TRACK = 'track'
}

export enum FBPixelEventTypeEnum {
  PAGE_VIEW = 'PageView',
  ADD_PAYMENT_INFO = 'AddPaymentInfo',
  COMPLETE_REGISTRATION = 'CompleteRegistration',
  ADD_TO_CART = 'AddToCart',
  WINNERS_PODIUM_LIVE_DRAW = 'WinnersPodiumLiveDraw',
  WINNERS_PODIUM_LIVE_DRAW_VIDEO = 'WinnersPodiumLiveDrawVideo',
  WINNERS_PODIUM_WINNER_VIDEO = 'WinnersPodiumWinnerVideo'
}

const validateProfileProperties = (profile: UserProfile) => {
  if (isNil(profile)) {
    return false
  }

  const { firstName, email } = profile

  return firstName && email
}

export const getFBPixelAdvancedMatchingObject = async (
  profile: UserProfile
): Promise<Partial<IAdvancedMatchingObject>> => {
  const advancedMatching: Partial<IAdvancedMatchingObject> = {}
  const isValid = validateProfileProperties(profile)

  if (!isValid) {
    return advancedMatching
  }

  const promises: Promise<void | string>[] = [
    hashData(profile.email),
    hashData(profile.firstName)
  ]

  if (profile.phoneNumber) {
    promises.push(
      hashData(profile.phoneNumber).then(hashedPh => {
        Object.assign(advancedMatching, {
          ph: hashedPh
        })
      })
    )
  }

  if (profile.birthday) {
    promises.push(
      hashData(
        new Date(profile.birthday)
          .toLocaleDateString()
          .split('/')
          .reverse()
          .join('')
      ).then(hashedBirthday => {
        Object.assign(advancedMatching, {
          db: hashedBirthday
        })
      })
    )
  }

  if (profile.town && profile.postCode) {
    promises.push(
      hashData(profile.town).then(hashedTown => {
        Object.assign(advancedMatching, {
          ct: hashedTown
        })
      }),
      hashData(profile.postCode).then(hashedPostCode => {
        Object.assign(advancedMatching, {
          zip: hashedPostCode
        })
      })
    )
  }

  if (profile.lastName) {
    promises.push(
      hashData(profile.lastName).then(hashedLn => {
        Object.assign(advancedMatching, { ln: hashedLn })
      })
    )
  }

  const [hashedEm, hashedFn] = await Promise.all(promises)

  if (profile.userId) {
    advancedMatching.external_id = profile.userId
  }

  Object.assign(advancedMatching, {
    em: hashedEm,
    fn: hashedFn
  })

  return advancedMatching
}

export const init = (
  advancedMatching: Partial<IAdvancedMatchingObject> = {}
) => {
  if (!config.facebook.isAvailable) {
    return
  }

  try {
    if (isBrowser() && typeof window.fbq === 'function') {
      window.fbq<FBPixelEventEnum, string, Partial<IAdvancedMatchingObject>>(
        FBPixelEventEnum.INIT,
        config.facebook.id,
        advancedMatching
      )
    }
  } catch (error) {
    console.error(error)
  }
}

export const pageView = () => {
  if (!config.facebook.isAvailable) {
    return
  }

  try {
    if (isBrowser() && typeof window.fbq === 'function') {
      window.fbq<FBPixelEventEnum, FBPixelEventTypeEnum>(
        FBPixelEventEnum.TRACK,
        FBPixelEventTypeEnum.PAGE_VIEW
      )
    }
  } catch (error) {
    console.error(error)
  }
}

export const track = <P = never>(
  eventType: FBPixelEventTypeEnum,
  params: P,
  options: Partial<{
    eventID: string
  }>
) => {
  if (!config.facebook.isAvailable) {
    return
  }

  try {
    if (isBrowser() && typeof window.fbq === 'function') {
      window.fbq<FBPixelEventEnum, FBPixelEventTypeEnum, P>(
        FBPixelEventEnum.TRACK,
        eventType,
        params,
        options
      )
    }
  } catch (error) {
    console.error(error)
  }
}
