import { isNil } from 'lodash-es'

import { type UserProfile } from '@elitecompetitions/client-api'

import { isBrowser } from '@helpers/isBrowser'

export enum HotJarEventEnum {
  IDENTIFY = 'identify'
}

const hotJarUserIdentificationEvent = ({
  isLoggedIn = false,
  profile = null
}: {
  isLoggedIn: boolean
  profile: UserProfile | null
}) => {
  if (isLoggedIn && !isNil(profile)) {
    try {
      const { email, userId } = profile

      if (isBrowser() && window.hj) {
        window.hj<
          HotJarEventEnum,
          {
            firebaseUid: string
          }
        >(HotJarEventEnum.IDENTIFY, email, {
          firebaseUid: userId
        })
      }
    } catch (error) {
      console.error(error)
    }
  }
}

export default hotJarUserIdentificationEvent
