import { css, styled } from '@mui/material'

export const StyledMainCarouselImage = styled('div')`
  width: 100%;
  height: 100%;
  border-radius: 12px;
  margin: 0 0 16px;
  cursor: pointer;
  background: var(--SecondaryVariant);
  position: relative;

  ${({
    theme: {
      breakpoints: {
        values: { sm }
      }
    }
  }) => {
    return css`
      & img {
        width: 100%;
        height: 100%;
        border-radius: 12px;
        object-fit: cover;

        @media (max-width: ${sm}px) {
          border-radius: 12px 12px 0 0;
        }
      }

      @media (max-width: ${sm}px) {
        margin: 0 0 20px;
      }
    `
  }}
`

export const StyledMainCarouselContent = styled('span')`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  margin: 0 auto;
  max-width: 740px;
  text-align: center;
`

export const StyledMainInfoTitle = styled('h3')`
  font-family: 'Orbitron';
  color: var(--Neutral1);
  font-size: 24px;
  line-height: 32px;
  text-transform: uppercase;
  border: 1px solid var(--Neutral1);
  padding: 12px;
  border-radius: 8px;
  display: inline-flex;

  ${({
    theme: {
      breakpoints: {
        values: { sm }
      }
    }
  }) => {
    return css`
      @media (max-width: ${sm}px) {
        font-size: 14px;
        line-height: 16px;

        & br {
          display: none;
        }
      }
    `
  }}
`
