import { useCallback, useEffect, useRef } from 'react'

import Link from 'next/link'

import { Stack } from '@mui/material'

import { Title } from '@lib/ui'

import { StyledCard } from './TrustReviewsContainer.styled'

const TrustReviewsContainer = () => {
  const trustBoxRef = useRef<HTMLDivElement | null>(null)

  const loadScript = useCallback(() => {
    const script = document.getElementById('trustpilot-script')

    if (!script) {
      const script = document.createElement('script')

      script.src =
        'https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js'
      script.defer = true
      script.id = 'trustpilot-script'
      script.onload = () => {
        if (window.Trustpilot) {
          window.Trustpilot.loadFromElement(trustBoxRef.current, true)
        }
      }

      document.body.appendChild(script)
    } else {
      if (window.Trustpilot) {
        window.Trustpilot.loadFromElement(trustBoxRef.current, true)
      }
    }
  }, [])

  useEffect(() => {
    /**
     *  If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
     *  If it's not, it means the script you pasted into <head /> isn't loaded just yet.
     *  When it is, it will automatically load the TrustBox.
     */
    if (/complete/.test(document.readyState)) {
      loadScript()
    } else {
      window.addEventListener('load', loadScript)
    }

    return () => {
      window.removeEventListener('load', loadScript)
    }
  }, [])

  return (
    <Stack direction="column" gap="16px" width="100%" component="section">
      <Title variant="h2">Trustpilot testimonials</Title>

      <StyledCard
        ref={trustBoxRef}
        id="trustbox-review-slider"
        data-locale="en-GB"
        data-template-id="53aa8912dec7e10d38f59f36"
        data-businessunit-id="5bd1a072a237c500018713c0"
        data-style-height="140px"
        data-style-width="100%"
        data-theme="dark"
        data-stars="4,5"
      >
        <noscript>
          <Link
            href="https://uk.trustpilot.com/review/elitecompetitions.co.uk"
            rel="noopener"
          >
            Trustpilot
          </Link>
        </noscript>
      </StyledCard>
    </Stack>
  )
}

export default TrustReviewsContainer
