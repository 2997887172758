import CompetitionCard from '../CompetitionCard'
import Title from '../Title'

import { Box, Grid2 } from '@mui/material'

import { type ICompetitionTilesGrid } from './CompetitionTilesGrid.types'
import { StyledTilesContainer } from './CompetitionTilesGrid.styled'

const CompetitionTilesGrid = (props: ICompetitionTilesGrid) => {
  const {
    id,
    variant = 'h2',
    heading = null,
    competitions = [],
    isPast = false,
    analytics = null
  } = props

  if (competitions.length === 0) {
    return null
  }

  return (
    <StyledTilesContainer id={id} spacing={2} component="section">
      {heading && <Title variant={variant}>{heading}</Title>}

      <Box>
        <Grid2 container spacing={2}>
          {competitions.map(competition => {
            return (
              <Grid2
                size={{ xs: 6, sm: 6, md: 4, lg: 4 }}
                key={`card-${competition.id}`}
              >
                <CompetitionCard
                  layoutMode="responsive"
                  competition={competition}
                  isPast={isPast}
                  analytics={analytics}
                />
              </Grid2>
            )
          })}
        </Grid2>
      </Box>
    </StyledTilesContainer>
  )
}

export default CompetitionTilesGrid
