import { orderBy } from 'lodash-es'

import { DateTime, Duration } from 'luxon'

import { useCallback, useMemo } from 'react'

import { Box, Stack, Grid2 } from '@mui/material'

import CompetitionCard from '../CompetitionCard'
import Countdown from '../Countdown'
import Title from '../Title'

import {
  StyledTimerContainer,
  StyledDigitTypography,
  StyledTimeTextTypography,
  StyledTilesContainer
} from './DrawTodayCompetitionTilesGrid.styled'
import { type IDrawTodayCompetitionTilesGridProps } from './DrawTodayCompetitionTilesGrid.types'

const DrawTodayCompetitionTilesGrid = (
  props: IDrawTodayCompetitionTilesGridProps
) => {
  const {
    id,
    heading = null,
    isPast = false,
    drawnTodayCompetitions = [],
    analytics = null
  } = props

  const drawnTodayCompsSorted = useMemo(() => {
    return orderBy(
      drawnTodayCompetitions,
      [competition => competition.isSoldOut],
      ['asc']
    )
  }, [drawnTodayCompetitions])

  const closestDrawnTodayCompetition = useMemo(() => {
    return orderBy(drawnTodayCompetitions, 'drawTime', 'asc')[0]
  }, [drawnTodayCompetitions])

  const renderCountdown = useCallback(
    (countDown: Duration, addLeadingZeros: (value: number) => string) => {
      return (
        <StyledTimerContainer
          direction="row"
          justifyContent="center"
          borderRadius="8px"
          bgcolor="var(--Secondary)"
          padding="8px 24px"
          gap={{
            lg: '32px',
            md: '16px',
            sm: '32px',
            xs: '32px'
          }}
        >
          <Stack direction="row" alignItems="flex-end" justifyContent="center">
            <StyledDigitTypography
              fontVariant="heading-3"
              weight="semibold"
              suppressHydrationWarning
            >
              {addLeadingZeros(countDown.days)}
            </StyledDigitTypography>

            <StyledTimeTextTypography
              lineHeight={{
                lg: '22px',
                md: '22px',
                sm: '20px',
                xs: '20px'
              }}
              fontVariant="body-4"
              weight="regular"
            >
              Days
            </StyledTimeTextTypography>
          </Stack>

          <Stack direction="row" alignItems="flex-end" justifyContent="center">
            <StyledDigitTypography
              fontVariant="heading-3"
              weight="semibold"
              suppressHydrationWarning
            >
              {addLeadingZeros(countDown.hours)}
            </StyledDigitTypography>

            <StyledTimeTextTypography
              lineHeight={{
                lg: '22px',
                md: '22px',
                sm: '20px',
                xs: '20px'
              }}
              fontVariant="body-4"
              weight="regular"
            >
              Hrs
            </StyledTimeTextTypography>
          </Stack>

          <Stack direction="row" alignItems="flex-end" justifyContent="center">
            <StyledDigitTypography
              fontVariant="heading-3"
              weight="semibold"
              suppressHydrationWarning
            >
              {addLeadingZeros(countDown.minutes)}
            </StyledDigitTypography>

            <StyledTimeTextTypography
              lineHeight={{
                lg: '22px',
                md: '22px',
                sm: '20px',
                xs: '20px'
              }}
              fontVariant="body-4"
              weight="regular"
            >
              Mins
            </StyledTimeTextTypography>
          </Stack>

          <Stack direction="row" alignItems="flex-end" justifyContent="center">
            <StyledDigitTypography
              fontVariant="heading-3"
              weight="semibold"
              suppressHydrationWarning
            >
              {addLeadingZeros(countDown.seconds)}
            </StyledDigitTypography>

            <StyledTimeTextTypography
              lineHeight={{
                lg: '22px',
                md: '22px',
                sm: '20px',
                xs: '20px'
              }}
              fontVariant="body-4"
              weight="regular"
            >
              Secs
            </StyledTimeTextTypography>
          </Stack>
        </StyledTimerContainer>
      )
    },
    []
  )

  return (
    <StyledTilesContainer spacing={2} id={id} component="section">
      {heading && <Title variant="h2">{heading}</Title>}

      <Box>
        <Grid2 container spacing={2}>
          <Grid2 size={{ xs: 12, sm: 12, md: 4, lg: 4 }}>
            <Countdown
              renderCountdown={renderCountdown}
              date={DateTime.fromISO(closestDrawnTodayCompetition.drawTime, {
                zone: closestDrawnTodayCompetition.timezone
              })}
            />
          </Grid2>
        </Grid2>
      </Box>

      <Box>
        <Grid2 container spacing={2}>
          {drawnTodayCompsSorted.map(competition => {
            return (
              <Grid2
                size={{ xs: 12, sm: 12, md: 4, lg: 4 }}
                key={competition.id}
              >
                <CompetitionCard
                  layoutMode="fixed"
                  showEndingSoonBadge={false}
                  competition={competition}
                  isPast={isPast}
                  analytics={analytics}
                />
              </Grid2>
            )
          })}
        </Grid2>
      </Box>
    </StyledTilesContainer>
  )
}

export default DrawTodayCompetitionTilesGrid
