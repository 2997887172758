import { Box, Stack, Grid2 } from '@mui/material'

import CompetitionCard from '../CompetitionCard'
import SpinToWinCompetitionsLogo from '../SpinToWinCompetitionsLogo'

import { type ISpinToWinCompetitionTilesGridProps } from './SpinToWinCompetitionTilesGrid.types'
import { StyledTilesContainer } from './SpinToWinCompetitionTilesGrid.styled'

const SpinToWinCompetitionTilesGrid = (
  props: ISpinToWinCompetitionTilesGridProps
) => {
  const {
    id,
    spinToWinCompetitions = [],
    isPast = false,
    analytics = null
  } = props

  return (
    <StyledTilesContainer spacing={1} id={id} component="section">
      <Stack direction="column" gap="16px" width="100%">
        <SpinToWinCompetitionsLogo />

        <Box>
          <Grid2 container spacing={2}>
            {spinToWinCompetitions.map(spinToWinCompetition => {
              return (
                <Grid2
                  size={{
                    xs: 12,
                    sm: 12,
                    md: 4,
                    lg: 4
                  }}
                  key={`spin-to-win-card-${spinToWinCompetition.id}`}
                >
                  <CompetitionCard
                    layoutMode="fixed"
                    canShowName={false}
                    competition={spinToWinCompetition}
                    isPast={isPast}
                    analytics={analytics}
                  />
                </Grid2>
              )
            })}
          </Grid2>
        </Box>
      </Stack>
    </StyledTilesContainer>
  )
}

export default SpinToWinCompetitionTilesGrid
