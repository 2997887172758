import { makeObservable, observable, action } from 'mobx'

export class CheckoutStore {
  isGooglePayProcessing: boolean = false

  isApplePayProcessing: boolean = false

  isPayPalProcessing: boolean = false

  isZeroPayProcessing: boolean = false

  isCkoProcessing: boolean = false

  constructor() {
    makeObservable(this, {
      isGooglePayProcessing: observable,
      isApplePayProcessing: observable,
      isPayPalProcessing: observable,
      isZeroPayProcessing: observable,
      isCkoProcessing: observable,

      setIsGooglePayLoading: action,
      setIsApplePayProcessing: action,
      setIsPayPalProcessing: action,
      setIsZeroPayProcessing: action,
      setIsCkoProcessing: action
    })

    this.setIsGooglePayLoading = this.setIsGooglePayLoading.bind(this)
    this.setIsApplePayProcessing = this.setIsApplePayProcessing.bind(this)
    this.setIsPayPalProcessing = this.setIsPayPalProcessing.bind(this)
    this.setIsZeroPayProcessing = this.setIsZeroPayProcessing.bind(this)
    this.setIsCkoProcessing = this.setIsCkoProcessing.bind(this)
  }

  setIsGooglePayLoading(isGooglePayProcessing: boolean) {
    this.isGooglePayProcessing = isGooglePayProcessing
  }

  setIsApplePayProcessing(isApplePayProcessing: boolean) {
    this.isApplePayProcessing = isApplePayProcessing
  }

  setIsPayPalProcessing(isPayPalProcessing: boolean) {
    this.isPayPalProcessing = isPayPalProcessing
  }

  setIsZeroPayProcessing(isZeroPayProcessing: boolean) {
    this.isZeroPayProcessing = isZeroPayProcessing
  }

  setIsCkoProcessing(isCkoProcessing: boolean) {
    this.isCkoProcessing = isCkoProcessing
  }
}

let checkoutStore: CheckoutStore

export function checkoutStoreFactory() {
  if (!process.browser) {
    checkoutStore = new CheckoutStore()
  }

  if (process.browser && !checkoutStore) {
    checkoutStore = new CheckoutStore()
  }

  return checkoutStore
}

export default checkoutStore
