import { Box, Stack, Grid2 } from '@mui/material'

import CompetitionCard from '../CompetitionCard'
import PennyCompetitionsLogo from '../PennyCompetitionsLogo'

import { type IPennyCompetitionTilesGridProps } from './PennyCompetitionTilesGrid.types'
import { StyledTilesContainer } from './PennyCompetitionTilesGrid.styled'

const PennyCompetitionTilesGrid = (props: IPennyCompetitionTilesGridProps) => {
  const { id, pennyCompetitions = [], isPast = false, analytics = null } = props

  return (
    <StyledTilesContainer spacing={1} id={id} component="section">
      <Stack direction="column" gap="16px" width="100%">
        <PennyCompetitionsLogo />

        <Box>
          <Grid2 container spacing={2}>
            {pennyCompetitions.map(pennyCompetition => {
              return (
                <Grid2
                  size={{
                    xs: 12,
                    sm: 12,
                    md: 4,
                    lg: 4
                  }}
                  key={`penny-card-${pennyCompetition.id}`}
                >
                  <CompetitionCard
                    layoutMode="fixed"
                    competition={pennyCompetition}
                    isPast={isPast}
                    analytics={analytics}
                  />
                </Grid2>
              )
            })}
          </Grid2>
        </Box>
      </Stack>
    </StyledTilesContainer>
  )
}

export default PennyCompetitionTilesGrid
