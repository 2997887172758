import { isNil } from 'lodash-es'

import { useEffect, useMemo } from 'react'

import { useQuery } from '@tanstack/react-query'

import { QueryClientKeysEnum } from '@enums'

import { useStores } from '@utils'
import { getServerErrorMessage } from '@utils/getServerError'

import { UsersService } from '@elitecompetitions/client-api'

export function useWalletBalance() {
  const { notificationStore } = useStores()

  const {
    data = null,
    refetch,
    isLoading = false,
    isRefetching = false,
    error = null
  } = useQuery({
    queryKey: [QueryClientKeysEnum.GET_WALLET_BALANCE],
    queryFn: () => UsersService.getWalletBalance(),
    enabled: false
  })

  const walletBalanceInternal = useMemo(() => {
    if (isNil(data)) {
      return 0
    }

    const { internal } = data

    return internal
  }, [data])

  const walletBalanceWithdrawable = useMemo(() => {
    if (isNil(data)) {
      return 0
    }

    const { withdrawable } = data

    return withdrawable
  }, [data])

  const isWalletBalanceLoading = useMemo(() => {
    return isLoading || isRefetching
  }, [isLoading, isRefetching])

  useEffect(() => {
    if (!isNil(error)) {
      const message = getServerErrorMessage({
        error,
        defaultMessage: 'Wallet balance loading was unsuccessful'
      })

      notificationStore.notify({
        message,
        type: 'error'
      })
    }
  }, [error])

  return {
    refetchWalletBalance: refetch,
    isWalletBalanceLoading,
    walletBalanceInternal,
    walletBalanceWithdrawable
  }
}
