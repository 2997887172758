import NextLink from 'next/link'

import { type ILinkProps } from './Link.types'
import { StyledTypography } from './Link.styled'

const Link = (props: ILinkProps) => {
  const { href, mode = 'dark', children, ...otherProps } = props

  return (
    <NextLink href={href} prefetch={false}>
      <StyledTypography mode={mode} {...otherProps}>
        {children}
      </StyledTypography>
    </NextLink>
  )
}

export default Link
