import GooglePayButton from '@google-pay/button-react'

import { css, styled } from '@mui/material'

export const StyledGooglePayButton = styled(GooglePayButton, {
  shouldForwardProp: propName => propName !== 'isDisabled'
})<{
  isDisabled: boolean
}>`
  .gpay-button {
    border-radius: 8px !important;
  }

  ${({ isDisabled = false }) => {
    if (isDisabled) {
      return css`
        cursor: default;
        pointer-events: none;
        filter: grayscale(80%);
      `
    }
  }}
`
