import 'react-toastify/dist/ReactToastify.min.css'

import { ToastContainer } from 'react-toastify'

const Toaster = () => {
  return (
    <ToastContainer
      autoClose={3000}
      hideProgressBar
      closeButton={false}
      icon={false}
    />
  )
}

export default Toaster
