export interface ICKOFormProps {
  onChangeTokenCKO: (tokenCKO: string) => void
  onReadyCKO: (isReadyCKO: boolean) => void
}

export enum CKOScriptStateEnum {
  LOADED = 'LOADED',
  LOADING = 'LOADING',
  ERROR = 'ERROR'
}
