import { isNil } from 'lodash-es'

import { useEffect, useState } from 'react'

import { child, getDatabase, ref, onChildChanged } from 'firebase/database'

import { useQuery } from '@tanstack/react-query'

import * as Sentry from '@sentry/nextjs'

import { QueryClientKeysEnum } from '@enums'

import { getFirebaseApp } from '@utils/Firebase'
import { getServerErrorMessage } from '@utils/getServerError'
import { useStores } from '@utils'

import { CompetitionsService } from '@elitecompetitions/client-api'

export function useJackpotChangedListener(props: {
  id: number
  firebaseId: string
  hasJackpot: boolean
}) {
  const { id, firebaseId, hasJackpot } = props

  const { notificationStore } = useStores()

  const [jackpotAmount, setJackpotAmount] = useState(0)

  const [charityJackpotAmount, setCharityJackpotAmount] = useState(0)

  const {
    data: competitionJackpotResponse = null,
    isLoading = false,
    error = null
  } = useQuery({
    queryKey: [QueryClientKeysEnum.GET_COMPETITION_JACKPOT, id],
    queryFn: () =>
      CompetitionsService.getCompetitionJackpot({
        id
      }),
    enabled: hasJackpot
  })

  const changeCompetitionHandler = (
    payload: Partial<{
      jackpot: {
        price: number
        charityPrice: number
      }
    }>
  ) => {
    const { jackpot = null } = payload

    if (!isNil(jackpot)) {
      const { price } = jackpot

      setJackpotAmount(price)
    }

    if (!isNil(jackpot)) {
      const { charityPrice } = jackpot

      setCharityJackpotAmount(charityPrice)
    }
  }

  useEffect(() => {
    if (!isNil(error)) {
      const message = getServerErrorMessage({
        error,
        defaultMessage: 'Jackpot loading was unsuccessful'
      })

      notificationStore.notify({
        message,
        type: 'error'
      })
    }
  }, [error])

  useEffect(() => {
    if (!isNil(competitionJackpotResponse)) {
      const { jackpotAmount, charityJackpotAmount } = competitionJackpotResponse

      setJackpotAmount(jackpotAmount)
      setCharityJackpotAmount(charityJackpotAmount)
    }
  }, [competitionJackpotResponse])

  useEffect(() => {
    let unsubscribe = null

    if (hasJackpot) {
      const database = getDatabase(getFirebaseApp())

      const compNodeRef = child(ref(database), `Competitions/${firebaseId}`)

      unsubscribe = onChildChanged(
        compNodeRef,
        compNodeSnap => {
          const { ref } = compNodeSnap
          const { key } = ref

          const changedVal = compNodeSnap.val()

          changeCompetitionHandler({
            [key]: changedVal
          })
        },
        error => {
          Sentry.captureException(error)
        }
      )
    }

    return () => {
      if (hasJackpot && !isNil(unsubscribe)) {
        unsubscribe()
      }
    }
  }, [])

  return {
    isLoading,
    jackpotAmount,
    charityJackpotAmount
  }
}
