export const GCP_STORAGE_URL = 'https://storage.cloud.google.com'

export const MAXIMUM_ALLOWED_TICKETS_PER_COMPETITION_PURCHASE = 1000

export const MAXIMUM_ALLOWED_TICKETS_PER_COMPETITION_PURCHASE_IN_ORDER_BUMP = 999

export const MINIMUM_ALLOWED_DISCOUNT_ZONES = 2

export const DEFAULT_LIVE_VIDEO_URL =
  'https://www.youtube.com/watch?v=k32oJQaePjg'

export const HAPPENING_SOON_TIME_OUT_IN_HOURS = 3

export const ELITE_PLACEHOLDER_IMG =
  '/assets/img/competition-default-image.webp'

export const API_TIMEOUT_MS = 60000

export const SEO_CHARACTER_LIMITS = {
  title: 67,
  description: 155
}

export const LUCKY_DIP_DEFAULT_VALUE = 10

export const FREE_COMP_DEFAULT_VALUE = 1

export const PRELIMINARY_AMOUNT_TEXT =
  'Please note, the amount is preliminary. The final amount will be calculated on the basket page.'
