import { useMemo } from 'react'

import { capitalize } from 'lodash-es'

import { ERROR_CODES } from '@elitecompetitions/client-api'

import { Stack } from '@mui/material'

import { Link, Typography } from '@lib/ui'

import { isAndroid, isIos } from '@utils'

import config from '@services/config'

import { RoutesEnum } from '@enums'

import { type ICartCouponErrorMessageProps } from './CartCouponErrorMessage.types'

const CartCouponErrorMessage = (props: ICartCouponErrorMessageProps) => {
  const { couponErrorCode, couponErrorMessage } = props

  const downloadAppUrl = useMemo(() => {
    if (isIos() || isAndroid()) {
      return config.externalLinks.oneLinkUrl
    }

    return RoutesEnum.ELITE_COMPETITIONS_APP
  }, [])

  if (couponErrorCode === ERROR_CODES.APP_ONLY_COUPON) {
    return (
      <Stack direction="column" gap="16px">
        <Typography
          fontVariant="body-3"
          weight="regular"
          color="var(--Neutral1)"
        >
          {capitalize(couponErrorMessage)}
        </Typography>

        <Link
          fontVariant="body-2"
          weight="medium"
          textAlign="center"
          href={downloadAppUrl}
        >
          Get the App
        </Link>
      </Stack>
    )
  }

  return (
    <Typography fontVariant="body-3" weight="regular" color="var(--Neutral1)">
      {capitalize(couponErrorMessage)}
    </Typography>
  )
}

export default CartCouponErrorMessage
