import { HttpStatusCode } from 'axios'

import { makeObservable, computed, observable, action } from 'mobx'

import { isNil } from 'lodash-es'

import {
  type CalculateCartPriceResultDTO,
  TicketsService,
  ERROR_CODES,
  type RoundUpOfferDTO,
  TransactionType
} from '@elitecompetitions/client-api'

import * as Sentry from '@sentry/nextjs'

import { getServerError } from '@utils/getServerError'

import { AbstractCartStore, type IBaseCart } from './AbstractCart'

export interface IDefaultCartStore
  extends IBaseCart<CalculateCartPriceResultDTO, TransactionType> {
  roundUpOffers: RoundUpOfferDTO[]

  syncAll: () => Promise<CalculateCartPriceResultDTO | void>
}

export class DefaultCartStore
  extends AbstractCartStore<CalculateCartPriceResultDTO, TransactionType>
  implements IDefaultCartStore
{
  type = TransactionType.CART

  constructor() {
    super()

    makeObservable(this, {
      type: observable,
      cartPrice: observable,
      isCheckoutButtonDisabled: observable,
      isRedeemWallet: observable,
      prevIsRedeemWallet: observable,
      appliedCoupon: observable,
      couponErrorCode: observable,
      couponErrorMessage: observable,
      isSyncingCart: observable,

      roundUpOffers: computed,
      couponCompetitions: computed,
      salesDiscount: computed,
      couponDiscount: computed,
      orderChecksum: computed,
      couponMessage: computed,
      walletDiscount: computed,
      finalPrice: computed,

      setIsRedeemWallet: action,
      setAppliedCoupon: action,
      setIsSyncingCart: action,
      setIsCheckoutButtonDisabled: action,
      setCouponErrorCode: action,
      setCouponErrorMessage: action,
      setCartPrice: action
    })

    this.setIsRedeemWallet = this.setIsRedeemWallet.bind(this)
    this.setIsCheckoutButtonDisabled =
      this.setIsCheckoutButtonDisabled.bind(this)
    this.setIsSyncingCart = this.setIsSyncingCart.bind(this)
    this.setAppliedCoupon = this.setAppliedCoupon.bind(this)
    this.setCouponErrorCode = this.setCouponErrorCode.bind(this)
    this.setCouponErrorMessage = this.setCouponErrorMessage.bind(this)
    this.setCartPrice = this.setCartPrice.bind(this)
  }

  get roundUpOffers() {
    if (isNil(this.cartPrice)) {
      return []
    }

    const { roundUpOffer } = this.cartPrice

    return roundUpOffer
  }

  async syncAll() {
    let cartPrice: CalculateCartPriceResultDTO | null = null
    let couponErrorCode: ERROR_CODES | null = null
    let couponErrorMessage: string | null = null

    if (!isNil(this.couponErrorCode) || !isNil(this.couponErrorMessage)) {
      this.setAppliedCoupon(null)
      this.setCouponErrorCode(null)
      this.setCouponErrorMessage(null)
    }

    try {
      this.setIsSyncingCart(true)

      cartPrice = await TicketsService.calculateCartPrice({
        requestBody: {
          couponCode: this.appliedCoupon,
          useWallet: this.isRedeemWallet
        }
      })
    } catch (error) {
      const serverError = getServerError(error)

      if (serverError) {
        if (
          serverError.statusCode !== HttpStatusCode.Conflict &&
          serverError.statusCode !== HttpStatusCode.BadRequest
        ) {
          Sentry.captureException(error)
        }

        if (
          serverError.statusCode === HttpStatusCode.Conflict ||
          serverError.statusCode === HttpStatusCode.BadRequest
        ) {
          const error = serverError.errors[0]

          couponErrorCode = error.code
          couponErrorMessage = error.message
        }
      }
    } finally {
      this.setIsSyncingCart(false)
    }

    if (!isNil(couponErrorCode) || !isNil(couponErrorMessage)) {
      this.setIsRedeemWallet(this.prevIsRedeemWallet)

      this.setCouponErrorCode(couponErrorCode)
      this.setCouponErrorMessage(couponErrorMessage)

      return this.cartPrice
    }

    if (isNil(cartPrice)) {
      return this.cartPrice
    }

    this.setCartPrice(cartPrice)

    return cartPrice
  }
}

let defaultCartStore: DefaultCartStore

export function defaultCartStoreFactory() {
  if (!process.browser) {
    defaultCartStore = new DefaultCartStore()
  }

  if (process.browser && !defaultCartStore) {
    defaultCartStore = new DefaultCartStore()
  }

  return defaultCartStore
}

export default defaultCartStore
