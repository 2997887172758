import { type ReactNode } from 'react'

import { type AppProps } from 'next/app'

import { DehydratedState, QueryClient } from '@tanstack/react-query'

import { isBrowser } from '@helpers/isBrowser'

import { type ComposedStore } from '@store'

export type ResultAppProps = Override<
  AppProps,
  {
    pageProps: {
      dehydratedState: DehydratedState
      children: ReactNode
    }
    isMaintenanceMode: boolean
    dehydratedState: DehydratedState
    initialMobxStore: ComposedStore
  }
>

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: 'always',
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5
    }
  }
})

export const getQueryClient = () => {
  if (isBrowser()) {
    return queryClient
  }

  return new QueryClient()
}

export function getHydrateState(appProps: ResultAppProps) {
  const { pageProps, dehydratedState } = appProps

  return {
    mutations: [],
    queries: [
      ...(pageProps?.dehydratedState?.queries || []),
      ...(dehydratedState?.queries || [])
    ]
  }
}
