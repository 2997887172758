import { HttpStatusCode } from 'axios'

import { isNil } from 'lodash-es'

import { useEffect, useMemo } from 'react'

import { useQuery } from '@tanstack/react-query'

import { QueryClientKeysEnum } from '@enums'

import { useStores } from '@utils'
import { getServerError, getServerErrorMessage } from '@utils/getServerError'

import { ConversationsService } from '@elitecompetitions/client-api'

export function useConversation() {
  const { notificationStore } = useStores()

  const {
    data = null,
    refetch,
    isLoading = false,
    isRefetching = false,
    error = null
  } = useQuery({
    queryKey: [QueryClientKeysEnum.GET_CONVERSATION],
    queryFn: () => ConversationsService.getMyConversation(),
    enabled: false
  })

  const isConversationLoading = useMemo(() => {
    return isLoading || isRefetching
  }, [isLoading, isRefetching])

  useEffect(() => {
    if (!isNil(error)) {
      const serverError = getServerError(error)

      if (serverError) {
        if (HttpStatusCode.NotFound === serverError.statusCode) {
          return
        }

        const message = getServerErrorMessage({
          error,
          defaultMessage: 'Conversation loading was unsuccessful'
        })

        notificationStore.notify({
          message,
          type: 'error'
        })
      }
    }
  }, [error])

  return {
    refetchConversation: refetch,
    isConversationLoading,
    conversation: data
  }
}
