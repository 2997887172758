import { Box, Grid2, Stack } from '@mui/material'

import { SavedCard, Typography } from '@lib/ui'

import { useSavedCards } from '@hooks/profile/useSavedCards'

import { type ICKOSavedCardsProps } from './CKOSavedCards.types'

const CKOSavedCards = (props: ICKOSavedCardsProps) => {
  const { isCkoProcessing, onProcessCKOPayment, disabled } = props

  const { cards, isLoadingSavedCards } = useSavedCards()

  if (cards.length === 0 && isLoadingSavedCards) {
    return (
      <Typography
        fontVariant="heading-4"
        weight="medium"
        textAlign="left"
        color="var(--Neutral1)"
      >
        Saved cards <i className="fa fa-spinner fa-spin" />
      </Typography>
    )
  }

  if (cards.length === 0 && !isLoadingSavedCards) {
    return null
  }

  return (
    <Stack direction="column" gap="16px" maxWidth="900px">
      <Typography
        fontVariant="heading-4"
        weight="medium"
        textAlign="left"
        color="var(--Neutral1)"
      >
        Saved cards
      </Typography>

      <Box>
        <Grid2 container spacing="16px">
          {cards.map((card, idx) => {
            return (
              <Grid2
                size={{
                  xs: 12,
                  md: 6
                }}
                key={idx}
              >
                <SavedCard
                  data={card}
                  disabled={disabled}
                  isLoading={isCkoProcessing}
                  isRemoveCard={false}
                  handleCardClick={onProcessCKOPayment}
                />
              </Grid2>
            )
          })}
        </Grid2>
      </Box>
    </Stack>
  )
}

export default CKOSavedCards
