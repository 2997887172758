import { Stack } from '@mui/material'

import { Skeleton, Typography } from '@lib/ui'

import { numericWithComma } from '@helpers'

import { type ICartDiscountProps } from './CartDiscount.types'

const CartDiscount = (props: ICartDiscountProps) => {
  const { title, discount, isLoading = false } = props

  if (discount === 0) {
    return null
  }

  if (isLoading) {
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <Skeleton mode="dark" animation="wave" width="60%" height={20} />

        <Skeleton mode="dark" animation="wave" width="20%" height={20} />
      </Stack>
    )
  }

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
    >
      <Typography fontVariant="body-2" weight="regular" color="var(--Neutral1)">
        {title}
      </Typography>

      <Typography fontVariant="body-2" weight="regular" color="var(--Third)">
        {`- £${numericWithComma(discount)}`}
      </Typography>
    </Stack>
  )
}

export default CartDiscount
