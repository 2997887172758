import { makeObservable, observable, action } from 'mobx'

export class BasketStore {
  isAdjusting = false

  isClearing = false

  isOpen = false

  isDisabled = false

  constructor() {
    makeObservable(this, {
      isAdjusting: observable,
      isClearing: observable,
      isOpen: observable,
      isDisabled: observable,

      setIsAdjusting: action,
      setIsClearing: action,
      setIsOpen: action,
      setIsDisabled: action
    })

    this.setIsAdjusting = this.setIsAdjusting.bind(this)
    this.setIsClearing = this.setIsClearing.bind(this)
    this.setIsOpen = this.setIsOpen.bind(this)
    this.setIsDisabled = this.setIsDisabled.bind(this)
  }

  setIsAdjusting(isAdjusting: boolean) {
    this.isAdjusting = isAdjusting
  }

  setIsClearing(isClearing: boolean) {
    this.isClearing = isClearing
  }

  setIsOpen(isOpen: boolean) {
    this.isOpen = isOpen
  }

  setIsDisabled(isDisabled: boolean) {
    this.isDisabled = isDisabled
  }
}

let basketStore: BasketStore

export function basketStoreFactory() {
  if (!process.browser) {
    basketStore = new BasketStore()
  }

  if (process.browser && !basketStore) {
    basketStore = new BasketStore()
  }

  return basketStore
}

export default basketStore
