import { Swiper } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/effect-coverflow'
import 'swiper/css/pagination'

import { type IMainCarouselProps } from './MainCarousel.types'
import {
  defaultCoverflowEffect,
  defaultPagination,
  defaultBreakpoints,
  defaultModules
} from './MainCarousel.data'

const MainCarousel = (props: IMainCarouselProps) => {
  const {
    children,
    coverflowEffect = defaultCoverflowEffect,
    pagination = defaultPagination,
    breakpoints = defaultBreakpoints,
    modules = defaultModules,
    paddingBottom = '20px',
    ...otherProps
  } = props

  return (
    <Swiper
      effect="coverflow"
      grabCursor={true}
      centeredSlides={true}
      slidesPerView={1.3}
      spaceBetween={0}
      loop={true}
      allowSlideNext={true}
      preventClicks={true}
      coverflowEffect={coverflowEffect}
      pagination={pagination}
      breakpoints={breakpoints}
      modules={modules}
      className="swiper-carousel"
      style={{
        paddingBottom
      }}
      {...otherProps}
    >
      {children}
    </Swiper>
  )
}

export default MainCarousel
