import { type CSSProperties } from 'react'

import { Box, type BoxProps, css, styled, Grid2 } from '@mui/material'

export const StyledCard = styled(Box, {
  shouldForwardProp: propName =>
    propName !== 'isOpen' &&
    propName !== 'visibleLines' &&
    propName !== 'maxHeightNotOpen' &&
    propName !== 'maxHeightOpen'
})<
  {
    isOpen: boolean
    visibleLines: number
    maxHeightNotOpen: CSSProperties['maxHeight']
    maxHeightOpen: CSSProperties['maxHeight']
  } & BoxProps
>`
  ${({
    isOpen = false,
    visibleLines = 3,
    maxHeightNotOpen = '76px',
    maxHeightOpen = '500px'
  }) => {
    if (!isOpen) {
      return css`
        max-height: ${maxHeightNotOpen} !important;
        height: ${maxHeightNotOpen} !important;
        overflow: hidden !important;

        display: -webkit-box;
        -webkit-line-clamp: ${visibleLines};
        -webkit-box-orient: vertical;
      `
    }

    return css`
      max-height: ${maxHeightOpen} !important;
      height: ${maxHeightOpen} !important;
    `
  }}

  color: var(--Neutral1);
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  border-radius: 8px;
  background: var(--Neutral8);
  position: relative;
  transition:
    0.3s height ease-in-out,
    0.6s z-index cubic-bezier(0, 0.58, 1, 0.96);
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: var(--Neutral6) transparent;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--Neutral6);
    border-radius: 6px;
  }

  h2:not(:first-of-type),
  h3:not(:first-of-type),
  h4:not(:first-of-type),
  h5:not(:first-of-type),
  h6:not(:first-of-type) {
    margin-top: 2.5rem;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 500;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 22px;
  }

  h4 {
    font-size: 20px;
  }

  h5 {
    font-size: 18px;
  }

  h6 {
    font-size: 16px;
  }
`

export const StyledGridItem = styled(Grid2)`
  position: relative;
`

export const StyledButton = styled('button', {
  shouldForwardProp: propName => propName !== 'isOpen'
})<{
  isOpen: boolean
}>`
  cursor: pointer;
  position: absolute;
  font-size: 16px;
  background: none;
  border: none;
  right: 0;
  color: var(--Neutral5);
  padding: 0.2rem 0.5rem;
  border-radius: 8px;

  ${({ isOpen = false }) => {
    if (!isOpen) {
      return css`
        background: var(--Neutral8);
      `
    }
  }}
`

export const StyledReadMoreButton = styled(StyledButton)`
  bottom: 0;
`

export const StyledCloseButton = styled(StyledButton)`
  top: 0;
  bottom: auto;
`
