import { Typography } from '@lib/ui'

import { Stack } from '@mui/material'

import {
  StyledAmexIcon,
  StyledMastercardIcon,
  StyledVisaIcon
} from './CKOWeAccept.styled'

const CKOWeAccept = () => {
  return (
    <Stack direction="row" gap="8px" alignItems="center">
      <Typography fontVariant="body-3" weight="regular" color="var(--Neutral3)">
        We accept
      </Typography>

      <Stack direction="row" gap="8px">
        <StyledVisaIcon viewBox="0 0 34 24" />

        <StyledMastercardIcon viewBox="0 0 35 24" />

        <StyledAmexIcon viewBox="0 0 35 24" />
      </Stack>
    </Stack>
  )
}

export default CKOWeAccept
