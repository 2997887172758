import {
  css,
  Box,
  Card,
  CardContent,
  CardMedia,
  styled,
  Typography,
  type CardMediaProps,
  type CardContentProps,
  type TypographyProps
} from '@mui/material'

import { type LayoutMode } from '@lib/types'

import { type IButtonProps } from '../Button/Button.types'
import Button from '../Button'

export const StyledCardMediaContainer = styled('div')`
  position: relative;
`

export const StyledCard = styled(Card, {
  shouldForwardProp: propName =>
    propName !== 'isBlackFriday' && propName !== 'canShowName'
})<{
  canShowName: boolean
  isBlackFriday: boolean
}>`
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: unset;
  flex-direction: column;
  border-radius: 8px;

  ${({ isBlackFriday = false, canShowName = false }) => {
    if (isBlackFriday) {
      return css`
        background: linear-gradient(
          110deg,
          var(--Neutral9) -27.72%,
          var(--Primary) 126.49%
        );
      `
    }

    if (canShowName) {
      return css`
        background: var(--Neutral8);
      `
    }

    return css`
      background: transparent;
    `
  }}
`

export const StyledSoldOutTypography = styled(Typography, {
  shouldForwardProp: propName => propName !== 'layoutMode'
})<
  {
    layoutMode: LayoutMode
  } & TypographyProps
>`
  color: var(--Neutral10);

  ${({ layoutMode = 'fixed' }) => {
    if (layoutMode === 'fixed') {
      return css`
        font-size: 14px !important;
        line-height: 18px !important;
      `
    }
  }}
`

export const StyledCompetitionNameTypography = styled(Typography, {
  shouldForwardProp: propName => propName !== 'layoutMode'
})<
  {
    layoutMode: LayoutMode
  } & TypographyProps
>`
  color: var(--Neutral1);

  ${({ layoutMode = 'fixed' }) => {
    if (layoutMode === 'fixed') {
      return css`
        font-size: 18px !important;
        line-height: 24px !important;
      `
    }
  }}
`
export const StyledClosedEventTypography = styled(Typography, {
  shouldForwardProp: propName => propName !== 'layoutMode'
})<
  {
    layoutMode: LayoutMode
  } & TypographyProps
>`
  color: var(--Neutral1);

  ${({ layoutMode = 'fixed' }) => {
    if (layoutMode === 'fixed') {
      return css`
        font-size: 48px !important;
        line-height: 40px !important;
      `
    }
  }}
`

export const StyledCardContent = styled(CardContent, {
  shouldForwardProp: propName =>
    propName !== 'layoutMode' && propName !== 'canShowName'
})<
  {
    canShowName: boolean
    layoutMode: LayoutMode
  } & CardContentProps
>`
  height: 100%;

  ${({
    layoutMode = 'fixed',
    canShowName = false,
    theme: {
      breakpoints: {
        values: { sm }
      }
    }
  }) => {
    if (layoutMode === 'fixed') {
      if (canShowName) {
        return css`
          padding-bottom: 16px !important;
          padding-left: 16px !important;
          padding-right: 16px !important;
        `
      } else {
        return css`
          padding: 16px 0 0 !important;
        `
      }
    }

    if (layoutMode === 'responsive') {
      if (canShowName) {
        return css`
          padding-bottom: 8px !important;
          padding-left: 8px !important;
          padding-right: 8px !important;

          @media (min-width: ${sm}px) {
            padding-bottom: 16px !important;
            padding-left: 16px !important;
            padding-right: 16px !important;
          }
        `
      } else {
        return css`
          padding: 8px 0 0 !important;

          @media (min-width: ${sm}px) {
            padding: 16px 0 0 !important;
          }
        `
      }
    }
  }}
`

export const StyledCardMedia = styled(CardMedia, {
  shouldForwardProp: propName =>
    propName !== 'isPast' && propName !== 'canShowName'
})<
  {
    canShowName: boolean
    isPast: boolean
  } & CardMediaProps<'img'>
>`
  position: relative;
  width: 100%;
  height: 100%;
  aspect-ratio: 16 / 9;

  img {
    width: 100%;
    height: 100%;

    ${({ canShowName = false }) => {
      if (canShowName) {
        return css`
          border-radius: 8px 8px 0 0 !important;
        `
      }

      return css`
        border-radius: 8px 8px 8px 8px !important;
      `
    }}
  }

  ${({ isPast = false }) => {
    if (isPast) {
      return css`
        filter: contrast(0.2);
      `
    }
  }}
`

export const StyledEnterButton = styled(Button, {
  shouldForwardProp: propName =>
    propName !== 'layoutMode' && propName !== 'isBlackFriday'
})<
  {
    isBlackFriday: boolean
    layoutMode: LayoutMode
  } & IButtonProps
>`
  ${({
    layoutMode = 'responsive',
    theme: {
      breakpoints: {
        values: { sm }
      }
    }
  }) => {
    if (layoutMode === 'responsive') {
      return css`
        border-radius: 4px;
        padding: 3px 16px;

        @media (min-width: ${sm}px) {
          border-radius: 6px;
          padding: 6px 16px;
        }
      `
    }
  }}

  ${({ isBlackFriday = false }) => {
    if (isBlackFriday) {
      return css`
        background: var(--Neutral10);
        box-shadow: inset 1px 1px 1px 0 rgba(255, 255, 255, 0.4);
      `
    }
  }}
`

export const StyledBadgeContainer = styled(Box, {
  shouldForwardProp: propName =>
    propName !== 'layoutMode' &&
    propName !== 'isOnTop' &&
    propName !== 'isJackpot'
})<
  {
    isJackpot: boolean
    isOnTop: boolean
    layoutMode: LayoutMode
  } & CardContentProps
>`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  left: 0;
  width: 100%;

  ${({
    layoutMode = 'fixed',
    theme: {
      breakpoints: {
        values: { sm }
      }
    }
  }) => {
    if (layoutMode === 'fixed') {
      return css`
        gap: 4px;
        padding-right: 8px;
      `
    }

    if (layoutMode === 'responsive') {
      return css`
        gap: 2px;
        padding-right: 4px;

        @media (min-width: ${sm}px) {
          gap: 4px;
          padding-right: 8px;
        }
      `
    }
  }}

  ${({
    layoutMode = 'fixed',
    isOnTop = false,
    theme: {
      breakpoints: {
        values: { sm }
      }
    }
  }) => {
    if (layoutMode === 'fixed') {
      if (isOnTop) {
        return css`
          top: 0;
          padding-top: 8px;
        `
      }

      return css`
        bottom: 0;
        padding-bottom: 8px;
      `
    }

    if (layoutMode === 'responsive') {
      if (isOnTop) {
        return css`
          top: 0;
          padding-top: 4px;

          @media (min-width: ${sm}px) {
            padding-top: 8px;
          }
        `
      }

      return css`
        bottom: 0;
        padding-bottom: 4px;

        @media (min-width: ${sm}px) {
          padding-bottom: 8px;
        }
      `
    }
  }}

  ${({ isJackpot = false }) => {
    if (isJackpot) {
      return css`
        left: -10px;
      `
    }
  }}
`

export const StyledClosedEventContainer = styled('div')`
  position: absolute;
  bottom: 50%;
  left: 0;
  width: 100%;
`

export const StyledClosedEvent = styled('div')`
  position: absolute;
  width: 100%;
  height: calc(100% - 30px);
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: var(--Neutral4);
`

export const StyledClosedEventBadge = styled('div')`
  padding: 8px 14px;
  border-radius: 8px;
  background: var(--Neutral7);
`

export const StyledSoldOutBadge = styled('div', {
  shouldForwardProp: propName => propName !== 'layoutMode'
})<{
  layoutMode: LayoutMode
}>`
  display: flex;
  justify-content: center;
  color: var(--Neutral10);
  background: var(--Neutral4);

  ${({
    layoutMode = 'fixed',
    theme: {
      breakpoints: {
        values: { sm }
      }
    }
  }) => {
    if (layoutMode === 'fixed') {
      return css`
        border-radius: 6px;
        padding: 7px 16px;
      `
    }

    if (layoutMode === 'responsive') {
      return css`
        border-radius: 4px;
        padding: 5px 16px;

        @media (min-width: ${sm}px) {
          border-radius: 6px;
          padding: 7px 16px;
        }
      `
    }
  }}
`

export const StyledJackpotAmount = styled('div')`
  position: absolute;
  font-size: clamp(10px, 4vw, 18px);
  top: 38%;
  width: 100%;

  @media (max-width: 900px) {
    font-size: clamp(18px, 5vw, 26px);
    top: 42%;
  }

  @media (max-width: 450px) {
    font-size: clamp(12px, 5vw, 16px);
    top: 38%;
  }
`

export const StyledCharityJackpotAmount = styled('div')`
  position: absolute;
  font-size: clamp(8px, 3vw, 10px);
  bottom: 30%;
  width: 100%;

  @media (max-width: 900px) {
    font-size: clamp(6px, 5vw, 10px);
    bottom: 26%;
  }

  @media (max-width: 450px) {
    bottom: 28%;
  }
`
