import { isNil } from 'lodash-es'

import { Stack } from '@mui/material'

import { useCallback, useMemo } from 'react'

import Link from 'next/link'

import tracking from '@utils/tracking'

import { getLocalAssetFromCDN } from '@helpers'

import config from '@services/config'

import { useJackpotChangedListener } from '@hooks/competition/useJackpotChangedListener'

import { StyledOdometer } from '@lib/ui/JackpotDataBadge/JackpotDataBadge.styled'

import CdnImage from '../CdnImage'
import CompetitionCardBadges from '../CompetitionCardBadges'

import {
  StyledEnterButton,
  StyledBadgeContainer,
  StyledCard,
  StyledCardContent,
  StyledCardMedia,
  StyledCardMediaContainer,
  StyledClosedEvent,
  StyledClosedEventBadge,
  StyledClosedEventContainer,
  StyledSoldOutBadge,
  StyledSoldOutTypography,
  StyledCompetitionNameTypography,
  StyledClosedEventTypography,
  StyledCharityJackpotAmount,
  StyledJackpotAmount
} from './CompetitionCard.styled'
import { type ICompetitionCardProps } from './CompetitionCard.types'

const CompetitionCard = (props: ICompetitionCardProps) => {
  const {
    competition,
    canShowName = true,
    isPast = false,
    analytics = null,
    layoutMode = 'fixed',
    showEndingSoonBadge = true
  } = props

  const { jackpotImage, hasJackpot, id, firebaseId } = competition

  const { jackpotAmount, charityJackpotAmount } = useJackpotChangedListener({
    firebaseId,
    id,
    hasJackpot
  })

  const canShowCompetitionBadges = useMemo(() => {
    const { isSoldOut = false } = competition

    return !isPast && !isSoldOut
  }, [isPast, competition])

  const canShowEnterBtn = useMemo(() => {
    const { isSoldOut = false } = competition

    return !isPast && !isSoldOut
  }, [isPast, competition])

  const href = useMemo(() => {
    const { slugUrl } = competition

    return `/competitions/${isPast ? `past/${slugUrl}` : slugUrl}`
  }, [competition, isPast])

  const handleAnalytics = useCallback(() => {
    if (isNil(analytics)) {
      return
    }

    tracking.customEvent(analytics.eventName, analytics.payload)
  }, [analytics])

  return (
    <Link href={href} prefetch={false}>
      <StyledCard
        canShowName={canShowName}
        isBlackFriday={config.events.isBlackFriday}
        onClick={handleAnalytics}
      >
        <StyledCardMediaContainer>
          <StyledCardMedia canShowName={canShowName} isPast={isPast}>
            {jackpotImage ? (
              <CdnImage
                alt={competition.name}
                image={competition.jackpotImage}
                placeholder={competition.imagePlaceholder}
                desktop="medium"
                fallbackSrc={getLocalAssetFromCDN(
                  '/assets/img/competition-default-image.webp'
                )}
              />
            ) : (
              <CdnImage
                alt={competition.name}
                image={competition.cdnImage}
                placeholder={competition.imagePlaceholder}
                desktop="medium"
                fallbackSrc={getLocalAssetFromCDN(
                  '/assets/img/competition-default-image.webp'
                )}
              />
            )}
            {jackpotImage && (
              <>
                <StyledJackpotAmount>
                  <StyledOdometer
                    showLabel={false}
                    enableScrollSpy={true}
                    scrollSpyOnce={true}
                    preserveValue={true}
                    end={jackpotAmount}
                    duration={3}
                    separator=","
                    decimals={2}
                    decimal="."
                    prefix="£"
                    className="position-absolute"
                    style={{
                      fontSize: 'inherit',
                      color: '#fff'
                    }}
                  />
                </StyledJackpotAmount>

                <StyledCharityJackpotAmount>
                  <StyledOdometer
                    showLabel={false}
                    enableScrollSpy={true}
                    scrollSpyOnce={true}
                    preserveValue={true}
                    end={charityJackpotAmount}
                    duration={3}
                    separator=","
                    decimals={2}
                    decimal="."
                    prefix="£"
                    className="position-absolute"
                    style={{
                      fontSize: 'inherit',
                      color: '#fff'
                    }}
                  />
                </StyledCharityJackpotAmount>
              </>
            )}
          </StyledCardMedia>

          <StyledBadgeContainer
            layoutMode={layoutMode}
            isOnTop={false}
            isJackpot={false}
          >
            {canShowCompetitionBadges && (
              <CompetitionCardBadges
                competition={competition}
                layoutMode={layoutMode}
                showEndingSoonBadge={showEndingSoonBadge}
              />
            )}
          </StyledBadgeContainer>

          {isPast && (
            <StyledClosedEventContainer>
              <StyledClosedEvent>
                <StyledClosedEventBadge>
                  <StyledClosedEventTypography
                    layoutMode={layoutMode}
                    fontFamily="Poppins"
                    fontWeight={400}
                    lineHeight={{
                      lg: '40px',
                      md: '32px',
                      sm: '32px',
                      xs: '24px'
                    }}
                    fontSize={{
                      lg: '48px',
                      md: '40px',
                      sm: '32px',
                      xs: '24px'
                    }}
                  >
                    Closed
                  </StyledClosedEventTypography>
                </StyledClosedEventBadge>
              </StyledClosedEvent>
            </StyledClosedEventContainer>
          )}
        </StyledCardMediaContainer>

        <StyledCardContent canShowName={canShowName} layoutMode={layoutMode}>
          <Stack
            height="100%"
            display="flex"
            flexDirection="column"
            spacing={2}
            justifyContent="space-between"
          >
            {canShowName && (
              <StyledCompetitionNameTypography
                layoutMode={layoutMode}
                fontFamily="Poppins"
                fontWeight={500}
                lineHeight={{
                  lg: '24px',
                  md: '22px',
                  sm: '20px',
                  xs: '18px'
                }}
                fontSize={{
                  lg: '18px',
                  md: '16px',
                  sm: '16px',
                  xs: '14px'
                }}
              >
                {competition.name}
              </StyledCompetitionNameTypography>
            )}

            {canShowEnterBtn && (
              <StyledEnterButton
                isBlackFriday={config.events.isBlackFriday}
                layoutMode={layoutMode}
                size="small"
              >
                Enter
              </StyledEnterButton>
            )}

            {!isPast && competition.isSoldOut && (
              <StyledSoldOutBadge layoutMode={layoutMode}>
                <StyledSoldOutTypography
                  layoutMode={layoutMode}
                  fontFamily="Poppins"
                  fontWeight={500}
                  lineHeight={{
                    lg: '18px',
                    md: '18px',
                    sm: '16px',
                    xs: '16px'
                  }}
                  fontSize={{
                    lg: '14px',
                    md: '14px',
                    sm: '12px',
                    xs: '12px'
                  }}
                >
                  Sold Out
                </StyledSoldOutTypography>
              </StyledSoldOutBadge>
            )}
          </Stack>
        </StyledCardContent>
      </StyledCard>
    </Link>
  )
}

export default CompetitionCard
