import { type ChangeEvent, useCallback, useMemo, useRef, useState } from 'react'

import { capitalize, isEmpty, isEqual, isNil } from 'lodash-es'

import { ERROR_CODES } from '@elitecompetitions/client-api'

import { Stack } from '@mui/material'

import { Button, TextField, Typography } from '@lib/ui'

import CartCouponErrorMessage from '../CartCouponErrorMessage'

import { type ICartCouponDiscountFormProps } from './CartCouponDiscountForm.types'

const CartCouponDiscountForm = (props: ICartCouponDiscountFormProps) => {
  const {
    isLoading = false,
    appliedCoupon,
    onApplyCoupon,
    couponMessage,
    couponErrorCode,
    couponErrorMessage,
    ...otherProps
  } = props

  const couponCodeFieldRef = useRef(null)

  const [couponCode, setCouponCode] = useState<string | null>(
    props.appliedCoupon
  )

  const handleChangeCouponCode = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { target } = event
      const { value } = target

      setCouponCode(value)
    },
    []
  )

  const handleClickApply = useCallback(async () => {
    if (couponCodeFieldRef.current) {
      couponCodeFieldRef.current.blur()
    }

    await onApplyCoupon(couponCode)
  }, [onApplyCoupon, couponCode])

  const isDisabledApplyBtn = useMemo(() => {
    return isLoading || isEmpty(couponCode) || isNil(couponCode)
  }, [couponCode, isLoading])

  const isCorrectCoupon = useMemo(() => {
    if (isLoading) {
      return false
    }

    if (!isNil(couponErrorCode) || !isNil(couponErrorMessage)) {
      return false
    }

    return (
      !isNil(appliedCoupon) &&
      !isNil(couponCode) &&
      isEqual(appliedCoupon, couponCode)
    )
  }, [
    appliedCoupon,
    couponCode,
    couponErrorCode,
    couponErrorMessage,
    isLoading
  ])

  const isIncorrectCoupon = useMemo(() => {
    if (isLoading) {
      return false
    }

    if (isNil(couponErrorCode) || isNil(couponErrorMessage)) {
      return false
    }

    return (
      Object.values(ERROR_CODES).includes(couponErrorCode as ERROR_CODES) &&
      !isNil(appliedCoupon) &&
      !isNil(couponCode) &&
      isEqual(appliedCoupon, couponCode)
    )
  }, [
    appliedCoupon,
    couponCode,
    couponErrorCode,
    couponErrorMessage,
    isLoading
  ])

  return (
    <Stack
      direction="column"
      alignItems="stretch"
      gap="8px"
      width="100%"
      padding={{
        lg: '0px',
        md: '0px',
        sm: '16px',
        xs: '16px'
      }}
      borderRadius={{
        lg: '0px',
        md: '0px',
        sm: '12px',
        xs: '12px'
      }}
      bgcolor={{
        lg: '0px',
        md: '0px',
        sm: 'var(--Neutral8)',
        xs: 'var(--Neutral8)'
      }}
      {...otherProps}
    >
      <Typography color="var(--Neutral3)" fontVariant="body-2" weight="regular">
        Coupon code
      </Typography>

      <Stack direction="column" gap="4px">
        <Stack direction="row" alignItems="flex-start" gap="8px">
          <TextField
            inputRef={couponCodeFieldRef}
            hiddenLabel
            mode="dark"
            fullWidth
            value={couponCode}
            onChange={handleChangeCouponCode}
            isSuccess={isCorrectCoupon}
            isError={isIncorrectCoupon}
            placeholder="Coupon code"
            name="couponCode"
            id="couponCode"
            type="text"
            autoComplete="couponCode"
          />

          <Button
            type="button"
            variant="primary"
            size="large"
            disabled={isDisabledApplyBtn}
            onClick={handleClickApply}
          >
            Apply
          </Button>
        </Stack>

        {isIncorrectCoupon ? (
          <CartCouponErrorMessage
            couponErrorMessage={couponErrorMessage}
            couponErrorCode={couponErrorCode}
          />
        ) : (
          <Typography
            fontVariant="body-3"
            weight="regular"
            color="var(--Neutral1)"
          >
            {capitalize(couponMessage)}
          </Typography>
        )}
      </Stack>
    </Stack>
  )
}

export default CartCouponDiscountForm
