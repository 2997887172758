import { defaultTo, isNil } from 'lodash-es'

import { v4 as uuid } from 'uuid'

import { useEffect, useMemo, useState } from 'react'

import qs from 'query-string'

import axios, { HttpStatusCode } from 'axios'

import { Settings } from 'luxon'

import { useRouter } from 'next/router'
import App, { type AppContext } from 'next/app'
import dynamic from 'next/dynamic'
import NextHead from 'next/head'

import nookies from 'nookies'

import { observer, Provider } from 'mobx-react'

import { ThemeProvider } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'

import { dehydrate, Hydrate, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import {
  ApplicationBannersService,
  ApplicationConfigService,
  CompetitionCategoriesService,
  type GetCompetitionCategoriesData,
  type CompetitionsGetCompetitionsData,
  CompetitionsService,
  CompetitionStatus,
  OpenAPI
} from '@elitecompetitions/client-api'

import 'react-responsive-carousel/lib/styles/carousel.min.css'
import 'react-modal-video/css/modal-video.min.css'

import '../../public/assets/css/bootstrap.min.css'
import '../../public/assets/css/style.css'
import '../../public/assets/css/responsive.css'

import { isBrowser } from '@helpers/isBrowser'

import initializeStore from '@store'

import {
  getHydrateState,
  getQueryClient,
  queryClient,
  type ResultAppProps
} from '@services/queryClient'
import config from '@services/config'

import {
  Loader,
  Typography,
  DeferredComponents,
  OfflineNotification
} from '@lib/ui'
import '@lib/styles/globals.css'

import { QueryClientKeysEnum } from '@enums'

import { getAppPlatform, getCookies } from '@utils'
import { RiskJs } from '@utils/RiskJs/RiskJs'
import { isServerError } from '@utils/getServerError'

import theme from '@theme'

import { authStoreFactory } from '@store/Auth'

import Maintenance from '@features/maintenance/Maintenance'

const LazyComponents = dynamic(() => import('@lib/ui/LazyComponents'), {
  ssr: false
})

const Footer = dynamic(() => import('@lib/ui/Footer'), {
  ssr: true
})

const Navbar = dynamic(() => import('@features/navigation/Navbar'), {
  ssr: true
})

Settings.defaultLocale = 'en-GB'

OpenAPI.BASE = config.coreApiUrl

OpenAPI.TOKEN = () => authStoreFactory().getToken()

OpenAPI.interceptors.request.use(async axiosRequestConfig => {
  const cookies = getCookies()
  const authStore = authStoreFactory()

  if (cookies.debugToken) {
    axiosRequestConfig.headers['x-debug-client'] = cookies.debugToken
  }

  if (cookies._fbp) {
    axiosRequestConfig.headers['fbp'] = cookies._fbp
  }

  if (cookies._fbc) {
    axiosRequestConfig.headers['fbc'] = cookies._fbc
  }

  axiosRequestConfig.headers['platform'] = getAppPlatform()
  axiosRequestConfig.headers['X-Request-Id'] = uuid()

  if (cookies.refreshToken) {
    axiosRequestConfig.headers['x-refresh-token'] = cookies.refreshToken
  }

  const accessToken = await authStore.getToken()

  if (accessToken) {
    axiosRequestConfig.headers['Authorization'] = `Bearer ${accessToken}`
  }

  return axiosRequestConfig
})

OpenAPI.interceptors.response.use(async axiosResponse => {
  if (!isNil(axiosResponse)) {
    const { data } = axiosResponse

    if (isBrowser() && isServerError(data)) {
      if (data.statusCode === HttpStatusCode.ServiceUnavailable) {
        window.location.reload()
      }

      if (
        (data.statusCode === HttpStatusCode.Forbidden &&
          data.errors.length === 1 &&
          data.errors[0].code === 'ACCESS_RESTRICTED') ||
        data.statusCode === HttpStatusCode.Unauthorized
      ) {
        const authStore = authStoreFactory()

        await authStore.logout()

        window.location.assign(`/login?referrer=${window.location.pathname}`)
      }
    }
  }

  return axiosResponse
})

const MyApp = (props: ResultAppProps) => {
  const { Component, pageProps, isMaintenanceMode, initialMobxStore } = props

  const [isRouteChanging, setIsRouteChanging] = useState(false)

  const router = useRouter()

  const mobxStore = useMemo(() => {
    return !isBrowser() ? initialMobxStore : initializeStore(initialMobxStore)
  }, [initialMobxStore])

  useEffect(() => {
    router.events.on('routeChangeComplete', () => {
      if (isBrowser()) {
        setTimeout(() => {
          window.scrollTo(0, 0)
        }, 0)
      }

      setIsRouteChanging(false)
    })

    router.events.on('routeChangeStart', () => {
      setIsRouteChanging(true)
    })

    router.events.on('routeChangeError', () => {
      setIsRouteChanging(false)
    })

    if (isBrowser()) {
      /**
       * @link https://developer.chrome.com/blog/history-api-scroll-restoration
       */
      if ('scrollRestoration' in history) {
        history.scrollRestoration = 'manual'
      }

      if (window.sessionStorage) {
        window.sessionStorage.setItem(
          'initial_query_params',
          qs.stringify(router.query)
        )
      }
    }
  }, [router])

  // if (true) {
  //   return <Maintenance />
  // }

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <Hydrate state={getHydrateState(props)}>
            <Provider {...mobxStore}>
              <NextHead>
                <meta
                  name="viewport"
                  content="width=device-width, initial-scale=1, maximum-scale=1"
                />
              </NextHead>

              <RiskJs />

              <OfflineNotification />

              <Navbar />

              <main
                style={{
                  minHeight: '75vh'
                }}
              >
                <Component {...pageProps} />
              </main>

              <Footer />

              <DeferredComponents Component={<LazyComponents />} />

              {isRouteChanging && (
                <Loader isOpen={true}>
                  <Typography
                    fontVariant="heading-3"
                    weight="semibold"
                    textAlign="center"
                    color="var(--Neutral1)"
                  >
                    Loading...
                  </Typography>
                </Loader>
              )}

              <ReactQueryDevtools initialIsOpen={false} />
            </Provider>
          </Hydrate>
        </QueryClientProvider>
      </ThemeProvider>
    </LocalizationProvider>
  )
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  const { ctx } = appContext
  const { req, query } = ctx

  let isMaintenanceMode = false

  const mobxStore = initializeStore()

  const queryClient = getQueryClient()

  const cookies = nookies.get(ctx)

  const { token, refreshToken } = cookies

  const debugToken = defaultTo(query.debugToken, cookies.debugToken) as
    | string
    | null

  OpenAPI.HEADERS = async () => {
    return {
      'X-Request-Id': uuid(),
      ...(debugToken
        ? {
            'x-debug-client': debugToken
          }
        : {}),
      ...(refreshToken
        ? {
            'x-refresh-token': refreshToken
          }
        : {}),
      ...(token
        ? {
            Authorization: `Bearer ${token}`
          }
        : {})
    }
  }

  try {
    await axios.get(`${config.coreApiUrl}/health`, {
      headers: {
        'X-Request-Id': uuid(),
        ...(debugToken
          ? {
              'x-debug-client': debugToken
            }
          : {}),
        ...(refreshToken
          ? {
              'x-refresh-token': refreshToken
            }
          : {}),
        ...(token
          ? {
              Authorization: `Bearer ${token}`
            }
          : {})
      }
    })
  } catch {
    isMaintenanceMode = true
  }

  const appProps = await App.getInitialProps(appContext)

  if (isMaintenanceMode) {
    return {
      ...appProps,
      isMaintenanceMode: true,
      initialMobxStore: mobxStore,
      dehydratedState: dehydrate(queryClient)
    }
  }

  if (req) {
    if (token) {
      try {
        const { data: user } = await axios.get(
          `${config.coreApiUrl}/api/v1/users/me`,
          {
            headers: {
              'X-Request-Id': uuid(),
              ...(debugToken
                ? {
                    'x-debug-client': debugToken
                  }
                : {}),
              ...(refreshToken
                ? {
                    'x-refresh-token': refreshToken
                  }
                : {}),
              ...(token
                ? {
                    Authorization: `Bearer ${token}`
                  }
                : {})
            }
          }
        )

        mobxStore.authStore.profile = user
        mobxStore.authStore.setToken(token)
      } catch {
        OpenAPI.TOKEN = undefined
      }
    }
  }

  const categoriesParams: GetCompetitionCategoriesData = {
    take: -1,
    filterIsPrimary: true
  }

  const competitionsParamsWithoutInvisible: CompetitionsGetCompetitionsData = {
    filterStatus: CompetitionStatus.ACTIVE,
    filterIsInvisible: false,
    take: -1
  }

  const competitionsParamsWithInvisible: CompetitionsGetCompetitionsData = {
    filterStatus: CompetitionStatus.ACTIVE,
    take: -1
  }

  await Promise.all([
    queryClient.prefetchQuery({
      queryKey: [QueryClientKeysEnum.GET_APPLICATION_CONFIG],
      queryFn: () =>
        ApplicationConfigService.getApplicationConfig({
          tenantId: 'default'
        })
    }),
    queryClient.prefetchQuery({
      queryKey: [QueryClientKeysEnum.GET_PROMOTION_BANNER_TEXT],
      queryFn: () => ApplicationBannersService.getApplicationBanner()
    }),
    queryClient.prefetchQuery({
      queryKey: [QueryClientKeysEnum.GET_CATEGORIES, categoriesParams],
      queryFn: () =>
        CompetitionCategoriesService.getCompetitionCategories(categoriesParams)
    }),

    // region Footer

    queryClient.prefetchQuery({
      queryKey: [
        QueryClientKeysEnum.GET_COMPETITIONS,
        competitionsParamsWithoutInvisible
      ],
      queryFn: () =>
        CompetitionsService.getCompetitions(competitionsParamsWithoutInvisible)
    }),

    /// endregion

    // region Basket widget, cart page and etc.

    queryClient.prefetchQuery({
      queryKey: [
        QueryClientKeysEnum.GET_COMPETITIONS,
        competitionsParamsWithInvisible
      ],
      queryFn: () =>
        CompetitionsService.getCompetitions(competitionsParamsWithInvisible)
    })

    // endregion
  ])

  return {
    ...appProps,
    isMaintenanceMode: false,
    initialMobxStore: mobxStore,
    dehydratedState: dehydrate(queryClient)
  }
}

export default observer(MyApp)

export function reportWebVitals(metric) {
  switch (metric.name) {
    case 'TTFB':
      console.log('TTFB', metric)
      break
    default:
      break
  }
}
