import { StyledTypography } from './Typography.styled'
import { type ITypographyProps } from './Typography.types'

const Typography = (props: ITypographyProps) => {
  const {
    fontFamily = 'Poppins',
    fontVariant = 'body-1',
    weight = 'regular',
    textDecoration = 'none',
    userSelect = 'auto',
    cursor = 'initial',
    color = 'var(--Neutral1)',
    children,
    ...otherProps
  } = props

  return (
    <StyledTypography
      textDecoration={textDecoration}
      userSelect={userSelect}
      cursor={cursor}
      fontFamily={fontFamily}
      fontVariant={fontVariant}
      color={color}
      weight={weight}
      {...otherProps}
    >
      {children}
    </StyledTypography>
  )
}

export default Typography
