import { floor } from 'lodash-es'

import { useCallback, useEffect } from 'react'

import { observer } from 'mobx-react'

import { useWalletBalance } from '@hooks/profile/useWalletBalance'

import { numericWithComma } from '@helpers'

import { type ICartWalletDiscountButtonProps } from './CartWalletDiscountButton.types'
import { StyledButton } from './CartWalletDiscountButton.styled'

const CartWalletDiscountButton = (props: ICartWalletDiscountButtonProps) => {
  const { onApplyWallet, walletDiscount, isLoading = false } = props

  const {
    isWalletBalanceLoading,
    refetchWalletBalance,
    walletBalanceInternal
  } = useWalletBalance()

  const renderBtnContent = useCallback(() => {
    if (isWalletBalanceLoading) {
      return (
        <>
          <i className="fa fa-spinner fa-spin" /> Loading...
        </>
      )
    }

    if (walletDiscount > 0) {
      return (
        <span>
          Use wallet credit{' '}
          <s
            style={{
              color: 'var(--Neutral4)'
            }}
          >
            {`£${numericWithComma(walletBalanceInternal)}`}
          </s>{' '}
          {`£${numericWithComma(floor(walletBalanceInternal - walletDiscount, 2))}`}
        </span>
      )
    }

    return `Use wallet credit £${numericWithComma(
      floor(walletBalanceInternal - walletDiscount, 2)
    )}`
  }, [isWalletBalanceLoading, walletBalanceInternal, walletDiscount])

  useEffect(() => {
    ;(async () => {
      await refetchWalletBalance()
    })()
  }, [])

  if (walletBalanceInternal === 0) {
    return null
  }

  return (
    <StyledButton
      mode="dark"
      width="100%"
      variant="secondary"
      type="button"
      size="large"
      disabled={isLoading || isWalletBalanceLoading}
      isApplied={walletDiscount > 0}
      onClick={onApplyWallet}
    >
      {renderBtnContent()}
    </StyledButton>
  )
}

export default observer(CartWalletDiscountButton)
