import { type ICheckoutPayPalProps } from './CheckoutPayPal.types'
import { StyledButton, StyledPayPalIcon } from './CheckoutPayPal.styled'

const CheckoutPayPal = (props: ICheckoutPayPalProps) => {
  const { onProcessPaypalPayment, disabled = false } = props

  return (
    <StyledButton onClick={onProcessPaypalPayment} isDisabled={disabled}>
      <StyledPayPalIcon viewBox="0 -6 30 35" />
    </StyledButton>
  )
}

export default CheckoutPayPal
