import { Cvv, CardNumber, ExpiryDate } from 'frames-react'

import { styled } from '@mui/material'

export const StyledCardNumber = styled(CardNumber)`
  width: 100%;
`

export const StyledExpiryDate = styled(ExpiryDate)`
  width: 6ch;
`

export const StyledCvv = styled(Cvv)`
  width: 4ch;
`
