import { Box, css, styled } from '@mui/material'

export const StyledApplePayButton = styled(Box, {
  shouldForwardProp: propName => propName !== 'isDisabled'
})<{
  isDisabled: boolean
}>`
  -webkit-appearance: -apple-pay-button;
  -apple-pay-button-style: white-outline;
  height: 40px !important;
  border-radius: 8px !important;
  width: 100% !important;

  ${({ isDisabled = false }) => {
    if (isDisabled) {
      return css`
        cursor: default;
        pointer-events: none;
        filter: grayscale(80%);
      `
    }
  }}
`
