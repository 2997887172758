import { Checkbox, Link, Typography } from '@lib/ui'

import { Stack } from '@mui/material'

import { type ICartTnC } from './CartTnC.types'

const CartTnC = (props: ICartTnC) => {
  const { checked, onChange, color } = props

  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      gap="8px"
    >
      <Checkbox checked={checked} onChange={onChange} />

      <Stack direction="row" justifyContent="flex-start" gap="4px">
        <Typography color={color} fontVariant="body-3" weight="regular">
          I am 18+ years old and agree to
        </Typography>

        <Link fontVariant="body-3" weight="regular" href="/tnc">
          T&C's
        </Link>
      </Stack>
    </Stack>
  )
}

export default CartTnC
