import { Card, CardContent, styled, Grid2 } from '@mui/material'

export const StyledGrid2 = styled(Grid2)`
  padding-bottom: 50px;
`

export const StyledCard = styled(Card)`
  border-radius: 8px;
  background: var(--Neutral8);
  height: 100%;
`

export const StyledCardContent = styled(CardContent)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: start;
  color: var(--Neutral1);
`
