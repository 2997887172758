import { defaultTo, isNil } from 'lodash-es'

import {
  type CalculateCartPriceResultDTO,
  type CalculateWheelPriceResultDTO,
  TransactionType,
  ERROR_CODES
} from '@elitecompetitions/client-api'

export interface IBaseCart<
  T extends CalculateWheelPriceResultDTO | CalculateCartPriceResultDTO,
  K extends TransactionType
> {
  type: K

  cartPrice: T | null

  isCheckoutButtonDisabled: boolean

  isRedeemWallet: boolean

  prevIsRedeemWallet: boolean

  appliedCoupon: string | null

  couponErrorCode: ERROR_CODES | null

  couponErrorMessage: string | null

  isSyncingCart: boolean

  finalPrice: number

  couponCompetitions: number[]

  salesDiscount: number

  couponDiscount: number

  walletDiscount: number

  orderChecksum: string | null

  couponMessage: string

  setIsRedeemWallet(isRedeemWallet: boolean): void

  setIsCheckoutButtonDisabled(isCheckoutButtonDisabled: boolean): void

  setIsSyncingCart(isSyncingCart: boolean): void

  setAppliedCoupon(appliedCoupon: string | null): void

  setCouponErrorCode(couponErrorCode: ERROR_CODES | null): void

  setCouponErrorMessage(couponErrorMessage: string | null): void

  setCartPrice(cartPrice: T | null): void
}

export abstract class AbstractCartStore<
  T extends CalculateWheelPriceResultDTO | CalculateCartPriceResultDTO,
  K extends TransactionType
> implements IBaseCart<T, K>
{
  abstract type: K

  cartPrice: T | null = null

  isCheckoutButtonDisabled = false

  isRedeemWallet = false

  prevIsRedeemWallet = false

  appliedCoupon: string | null = null

  couponErrorCode: ERROR_CODES | null = null

  couponErrorMessage: string | null = null

  isSyncingCart = false

  get couponCompetitions() {
    if (isNil(this.cartPrice)) {
      return []
    }

    const { couponCompetitions } = this.cartPrice

    return defaultTo(couponCompetitions, [])
  }

  get salesDiscount() {
    if (isNil(this.cartPrice)) {
      return 0
    }

    const { salesDiscount } = this.cartPrice

    return defaultTo(salesDiscount, 0)
  }

  get couponDiscount() {
    if (isNil(this.cartPrice)) {
      return 0
    }

    const { couponDiscount } = this.cartPrice

    return defaultTo(couponDiscount, 0)
  }

  get orderChecksum() {
    if (isNil(this.cartPrice)) {
      return null
    }

    const { orderChecksum } = this.cartPrice

    return orderChecksum
  }

  get couponMessage() {
    if (isNil(this.cartPrice)) {
      return null
    }

    const { couponMessage } = this.cartPrice

    return defaultTo(couponMessage, '')
  }

  get walletDiscount() {
    if (isNil(this.cartPrice)) {
      return 0
    }

    const { walletDiscount } = this.cartPrice

    return defaultTo(walletDiscount, 0)
  }

  get finalPrice() {
    if (isNil(this.cartPrice)) {
      return 0
    }

    const { finalPrice } = this.cartPrice

    return defaultTo(finalPrice, 0)
  }

  setIsRedeemWallet(isRedeemWallet: boolean) {
    this.prevIsRedeemWallet = this.isRedeemWallet
    this.isRedeemWallet = isRedeemWallet
  }

  setAppliedCoupon(appliedCoupon: string | null) {
    this.appliedCoupon = appliedCoupon
  }

  setIsSyncingCart(isSyncingCart: boolean) {
    this.isSyncingCart = isSyncingCart
  }

  setIsCheckoutButtonDisabled(isCheckoutButtonDisabled: boolean) {
    this.isCheckoutButtonDisabled = isCheckoutButtonDisabled
  }

  setCouponErrorCode(couponErrorCode: ERROR_CODES | null) {
    this.couponErrorCode = couponErrorCode
  }

  setCouponErrorMessage(couponErrorMessage: string | null) {
    this.couponErrorMessage = couponErrorMessage
  }

  setCartPrice(cartPrice: T | null) {
    this.cartPrice = cartPrice
  }
}
