import { useRouter } from 'next/router'

import { toString } from 'lodash-es'

import { useCallback } from 'react'

import { observer } from 'mobx-react'

import { useStores } from '@utils'

import { CurrencyTypeEnum } from '@enums'

import { StyledApplePayButton } from './CheckoutApplePay.styled'
import { type ICheckoutApplePayProps } from './CheckoutApplePay.types'

const CheckoutApplePay = (props: ICheckoutApplePayProps) => {
  const {
    disabled = false,
    finalPrice,
    onProcessApplePayPayment,
    onValidateApplePayMerchant
  } = props

  const router = useRouter()

  const { notificationStore } = useStores()

  const handleApplePay = useCallback(async () => {
    const applePaySession = new ApplePaySession(6, {
      countryCode: 'GB',
      currencyCode: CurrencyTypeEnum.GBP,
      // https://developer.apple.com/documentation/apple_pay_on_the_web/applepaypaymentrequest/1916122-supportednetworks
      supportedNetworks: ['visa', 'masterCard', 'amex', 'discover'],
      merchantCapabilities: ['supports3DS'],
      total: {
        label: 'Elite Competitions',
        amount: toString(finalPrice)
      }
    })

    applePaySession.onvalidatemerchant = async (
      event: ApplePayJS.ApplePayValidateMerchantEvent
    ) => {
      const { validationURL } = event

      // @ts-ignore
      const { data, status } = await onValidateApplePayMerchant(validationURL)

      if (!status) {
        notificationStore.notify({
          message: 'Apple Pay merchant validation failed.',
          type: 'error'
        })
      } else {
        applePaySession.completeMerchantValidation(data)
      }
    }

    applePaySession.onpaymentauthorized = async (
      event: ApplePayJS.ApplePayPaymentAuthorizedEvent
    ) => {
      const { payment } = event
      const { token } = payment

      const { response, pathname } = await onProcessApplePayPayment(token)

      const { status } = response

      if (status) {
        applePaySession.completePayment(ApplePaySession.STATUS_SUCCESS)
      } else {
        applePaySession.completePayment(ApplePaySession.STATUS_FAILURE)
      }

      await router.push(pathname)
    }

    applePaySession.begin()
  }, [
    finalPrice,
    notificationStore,
    onProcessApplePayPayment,
    onValidateApplePayMerchant,
    router
  ])

  return <StyledApplePayButton isDisabled={disabled} onClick={handleApplePay} />
}

export default observer(CheckoutApplePay)
