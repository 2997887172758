import { Typography } from '@lib/ui'

import { Stack, Grid2 } from '@mui/material'

import {
  StyledForm,
  StyledCardBody,
  StyledCardHeader,
  StyledContainer
} from './BaseFormContainer.styled'
import { type IBaseFormContainerProps } from './BaseFormContainer.types'

const BaseFormContainer = (props: IBaseFormContainerProps) => {
  const { children, headerTitle } = props

  return (
    <StyledContainer>
      <Grid2
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Grid2 size={{ xs: 12, sm: 12, md: 7 }}>
          <StyledCardHeader
            padding={{
              lg: '19px 16px',
              md: '19px 16px',
              sm: '13px 16px',
              xs: '13px 16px'
            }}
          >
            <Typography
              textAlign="center"
              fontVariant="heading-4"
              weight="semibold"
            >
              {headerTitle}
            </Typography>
          </StyledCardHeader>

          <StyledCardBody
            padding={{
              lg: '40px 100px',
              md: '30px 80px',
              sm: '20px 60px',
              xs: '24px 16px'
            }}
          >
            <StyledForm>
              <Stack
                direction="column"
                alignItems="stretch"
                width="100%"
                maxWidth="400px"
                gap="16px"
              >
                {children}
              </Stack>
            </StyledForm>
          </StyledCardBody>
        </Grid2>
      </Grid2>
    </StyledContainer>
  )
}

export default BaseFormContainer
