import axios, {
  type AxiosRequestConfig,
  type InternalAxiosRequestConfig
} from 'axios'

import { v4 as uuid } from 'uuid'

import config from '@services/config'

import { getAppPlatform, getCookies } from '@utils'

import { authStoreFactory } from '@store/Auth'

import { API_TIMEOUT_MS } from '@helpers/constants'

export const legacyApi = axios.create()

legacyApi.interceptors.request.use(
  async (axiosRequestConfig: InternalAxiosRequestConfig) => {
    const cookies = getCookies()
    const authStore = authStoreFactory()

    if (cookies.debugToken) {
      axiosRequestConfig.headers['x-debug-client'] = cookies.debugToken
    }

    if (cookies._fbp) {
      axiosRequestConfig.headers['fbp'] = cookies._fbp
    }

    if (cookies._fbc) {
      axiosRequestConfig.headers['fbc'] = cookies._fbc
    }

    axiosRequestConfig.headers['platform'] = getAppPlatform()
    axiosRequestConfig.headers['X-Request-Id'] = uuid()

    const accessToken = await authStore.getToken()

    if (accessToken) {
      axiosRequestConfig.headers['Authorization'] = `Bearer ${accessToken}`
    }

    return axiosRequestConfig
  }
)

legacyApi.defaults.timeout = API_TIMEOUT_MS

const requestV2 = async (
  url: string,
  method = 'GET',
  options: AxiosRequestConfig = {}
) => {
  const response = await legacyApi.request({
    baseURL: config.api.v2,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    url,
    method,
    ...options
  })

  return response
}

export default requestV2
