import { css, styled } from '@mui/material'

import { PayPalIcon } from '@lib/icons'

export const StyledButton = styled('button', {
  shouldForwardProp: propName => propName !== 'isDisabled'
})<{
  isDisabled: boolean
}>`
  width: 100%;
  height: 40px;
  border-radius: 8px;
  border: none;
  background-color: var(--Neutral1);
  cursor: pointer;
  user-select: none;

  ${({ isDisabled = false }) => {
    if (isDisabled) {
      return css`
        cursor: default;
        pointer-events: none;
        filter: grayscale(80%);
      `
    }
  }}

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: var(--Neutral1);
    }
  }

  &:focus,
  &:active {
    border: 1px solid var(--Secondary);
  }
`

export const StyledPayPalIcon = styled(PayPalIcon)`
  width: 36px;
  height: 32px;
`
