import { type CSSProperties } from 'react'

import { defaultTo } from 'lodash-es'

import { Box, css, styled } from '@mui/material'

import Odometer from '../Odometer'

export const StyledOdometer = styled(Odometer, {
  shouldForwardProp: propName => propName !== 'showLabel'
})<{
  showLabel?: boolean
  odometerWidth?: CSSProperties['width']
  odometerJustifyContent?: CSSProperties['justifyContent']
}>`
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-family: 'Orbitron', sans-serif;
  font-weight: 600;

  ${({ showLabel = false, odometerWidth, odometerJustifyContent }) => {
    if (showLabel) {
      return css`
        justify-content: ${defaultTo(odometerJustifyContent, 'left')};
        width: ${defaultTo(odometerWidth, '45%')};
      `
    }

    return css`
      justify-content: ${defaultTo(odometerJustifyContent, 'center')};
      width: ${defaultTo(odometerWidth, '100%')};
    `
  }}

  ${({
    theme: {
      breakpoints: {
        values: { xs, sm, md, lg }
      }
    }
  }) => {
    return css`
      @media (min-width: ${xs}px) {
        font-size: 16px;
        line-height: 22px;
      }

      @media (min-width: ${sm}px) {
        font-size: 18px;
        line-height: 24px;
      }

      @media (min-width: ${md}px) {
        font-size: 18px;
        line-height: 24px;
      }

      @media (min-width: ${lg}px) {
        font-size: 20px;
        line-height: 26px;
      }
    `
  }}
`

export const StyledJackpotCharityDataBadgeContainer = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow:
    inset 0px 0px 1px 1px rgba(255, 255, 255, 0.1),
    inset 0px 0px 1px 1px rgba(255, 255, 255, 0.1);
  background: var(--SecondaryVariant);
`
