import { Box, Stack, Grid2 } from '@mui/material'

import CompetitionCard from '../CompetitionCard'
import InstaWinCompetitionsLogo from '../InstaWinCompetitionsLogo'

import { type IInstaWinCompetitionTilesGridProps } from './InstaWinCompetitionTilesGrid.types'
import { StyledTilesContainer } from './InstaWinCompetitionTilesGrid.styled'

const InstaWinCompetitionTilesGrid = (
  props: IInstaWinCompetitionTilesGridProps
) => {
  const {
    id,
    instaWinCompetitions = [],
    isPast = false,
    analytics = null
  } = props

  return (
    <StyledTilesContainer spacing="16px" id={id} component="section">
      <Stack direction="column" gap="16px" width="100%">
        <InstaWinCompetitionsLogo />

        <Box>
          <Grid2 container spacing={2}>
            {instaWinCompetitions.map(instaWinCompetition => {
              return (
                <Grid2
                  size={{
                    xs: 12,
                    sm: 12,
                    md: 4,
                    lg: 4
                  }}
                  key={`instant-win-card-${instaWinCompetition.id}`}
                >
                  <CompetitionCard
                    layoutMode="fixed"
                    competition={instaWinCompetition}
                    isPast={isPast}
                    analytics={analytics}
                  />
                </Grid2>
              )
            })}
          </Grid2>
        </Box>
      </Stack>
    </StyledTilesContainer>
  )
}

export default InstaWinCompetitionTilesGrid
