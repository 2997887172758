import { css, styled } from '@mui/material'

import { Button } from '@lib/ui'

export const StyledButton = styled(Button, {
  shouldForwardProp: propName => propName !== 'isApplied'
})<{ isApplied: boolean }>`
  ${({ isApplied = false }) => {
    if (isApplied) {
      return css`
        background: rgba(66, 222, 181, 0.1);
      `
    }
  }}
`
