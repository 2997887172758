import { Tooltip as MuiTooltip } from '@mui/material'

import { type ITooltipProps } from './Tooltip.types'

const Tooltip = (props: ITooltipProps) => {
  const { userSelect = 'auto', ...otherProps } = props

  return (
    <MuiTooltip
      componentsProps={{
        tooltip: {
          sx: {
            cursor: 'context-menu',
            userSelect
          }
        }
      }}
      {...otherProps}
    />
  )
}

export default Tooltip
