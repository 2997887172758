import { get, isNil, uniq } from 'lodash-es'

import { observer } from 'mobx-react'

import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import Image from 'next/image'
import dynamic from 'next/dynamic'
import Link from 'next/link'

import { useStores } from '@utils'
import {
  CompetitionCategorySlugEnum,
  MyAccountRoutesEnum,
  RoutesEnum
} from '@enums'

import { PromotionsService } from '@elitecompetitions/client-api'

import {
  registerMessageNotificationListener,
  registerPromotionListener
} from '@api/Messages'

import { getLocalAssetFromCDN, numericWithComma } from '@helpers'

import { Container, Stack } from '@mui/material'

import { useApplicationConfig } from '@hooks/useApplicationConfig'
import { useWalletBalance } from '@hooks/profile/useWalletBalance'
import { useConversation } from '@hooks/profile/useConversation'

import { Typography } from '@lib/ui'

const Basket = dynamic(() => import('@features/basket/components/Basket'), {
  ssr: false
})

import { CloseIcon, HamburgerIcon, CartIcon, WalletIcon } from '@lib/icons'

import BannerPromotion from '../BannerPromotion'
import WalletModal from '../WalletModal'

import {
  StyledHeader,
  StyledMobileLogo,
  StyledMainMenu,
  StyledMainMenuList,
  StyledNavbar,
  StyledNavbarButton,
  StyledDesktopLogo,
  StyledButton,
  StyledScrollContainer,
  StyledMainMenuButtonSection,
  StyledBadge,
  StyledLandingItem,
  StyledWorkWithUsItem
} from './Navbar.styled'
import { type IMessageData, type IUserChatData } from './Navbar.types'

const Navbar = observer(function Navbar() {
  const { ticketsStore, authStore, notificationStore, basketStore } =
    useStores()

  const { walletMoneyBackPercentage, showApplicationBanner, promoPageConfig } =
    useApplicationConfig()

  const {
    isOpen: isOpenBasket = false,
    isDisabled: isDisabledBasket = false,
    isAdjusting: isAdjustingBasket = false,
    isClearing: isClearingBasket = false
  } = basketStore

  const { totalTicketsCount } = ticketsStore

  const { profile } = authStore

  const isLoggedIn = Boolean(profile)

  const {
    walletBalanceInternal,
    isWalletBalanceLoading,
    refetchWalletBalance
  } = useWalletBalance()

  const { conversation, refetchConversation } = useConversation()

  const [isWalletModalOpen, setIsWalletModalOpen] = useState(false)

  const [notificationsCount, setNotificationsCount] = useState(0)

  const [unreadMessageCount, setUnreadMessageCount] = useState(0)

  const [promotions, setPromotions] = useState<string[] | null>(null)

  const [readPromotionNotificationsIds, setReadPromotionNotificationsIds] =
    useState<string[] | null>(null)

  const hamburgerMenuRef = useRef(null)

  const headerRef = useRef<HTMLDivElement | null>(null)

  const notificationListener = useRef(null)

  const promotionsListener = useRef(null)

  const isDisabledBasketButton = useMemo(() => {
    return (
      isClearingBasket ||
      isAdjustingBasket ||
      (isOpenBasket && isDisabledBasket)
    )
  }, [isClearingBasket, isAdjustingBasket, isOpenBasket, isDisabledBasket])

  const closeMenu = () => {
    headerRef.current.classList.remove('active')

    document.body.style.overflow = 'auto'
  }

  const openWalletModal = useCallback(() => {
    setIsWalletModalOpen(true)
  }, [])

  const handleCloseBasket = useCallback(() => {
    if (isAdjustingBasket || isClearingBasket) {
      return
    }

    basketStore.setIsOpen(false)
  }, [basketStore, isAdjustingBasket, isClearingBasket])

  const onToggleBasket = useCallback(
    event => {
      event.preventDefault()

      basketStore.setIsOpen(!isOpenBasket)
    },
    [basketStore, isOpenBasket]
  )

  const unsubscribePromotionsListener = () => {
    if (!isNil(promotionsListener.current)) {
      promotionsListener.current.unsubscribeChangeListener()
      promotionsListener.current.unsubscribeAddListener()
    }
  }

  const unsubscribeNotificationListener = () => {
    if (!isNil(notificationListener.current)) {
      notificationListener.current.unsubscribeChangeListener()
    }
  }

  const unsubscribeListeners = () => {
    unsubscribePromotionsListener()
    unsubscribeNotificationListener()
  }

  const messageNotificationsListener = async () => {
    unsubscribeNotificationListener()

    const { profile } = authStore

    if (isNil(profile)) {
      return
    }

    if (!isNil(conversation)) {
      notificationListener.current = registerMessageNotificationListener(
        conversation.id,
        (userChatData: IUserChatData) => {
          setReadPromotionNotificationsIds(
            get(userChatData, 'read_promotion_ids', [])
          )
          setUnreadMessageCount(get(userChatData, 'unread_message_count', 0))
        }
      )
    } else {
      setReadPromotionNotificationsIds([])
      setUnreadMessageCount(0)
    }
  }

  const subscribePromotionsListener = async () => {
    const { profile } = authStore

    if (isNil(profile)) {
      return
    }

    const promotions = await PromotionsService.getPromotions({})

    setPromotions(promotions.data.map(promotion => promotion.id))

    promotionsListener.current = registerPromotionListener(
      (messageData: IMessageData) => {
        if (isNil(messageData)) {
          return
        }

        const { last_message, type } = messageData

        if (isNil(last_message) || type !== 'promotion') {
          return
        }

        setPromotions(prev => uniq([...prev, last_message.id]))
      }
    )
  }

  useEffect(() => {
    return () => {
      unsubscribeListeners()
    }
  }, [])

  useEffect(() => {
    ;(async () => {
      if (profile) {
        await subscribePromotionsListener()

        await refetchConversation()
        await refetchWalletBalance()
      } else {
        unsubscribePromotionsListener()
      }
    })()
  }, [profile])

  useEffect(() => {
    ;(async () => {
      if (conversation) {
        await messageNotificationsListener()
      }
    })()
  }, [conversation])

  useEffect(() => {
    if (readPromotionNotificationsIds && promotions) {
      const count =
        unreadMessageCount +
        promotions.filter(
          promotion => !readPromotionNotificationsIds.includes(promotion)
        ).length

      setNotificationsCount(count)
      notificationStore.setNotificationCount(count)
    }
  }, [readPromotionNotificationsIds, promotions, unreadMessageCount])

  return (
    <StyledNavbar>
      <Container suppressHydrationWarning={true}>
        <StyledHeader ref={headerRef}>
          <div className="d-flex flex-row justify-content-start align-items-center d-lg-none">
            <StyledNavbarButton
              type="button"
              className="mr-3"
              ref={hamburgerMenuRef}
              onClick={e => {
                e.stopPropagation()

                document.body.style.overflow = 'hidden'

                headerRef.current.classList.toggle('active')
              }}
            >
              <StyledBadge
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
                badgeContent={notificationsCount}
                showZero={false}
              >
                <HamburgerIcon width="25" height="24" viewBox="0 5 25 24" />
              </StyledBadge>
            </StyledNavbarButton>

            <StyledMobileLogo href="/" prefetch={false}>
              <Image
                src={getLocalAssetFromCDN('/assets/logos/main-logo.svg')}
                alt="Elite Competitions Logo"
                priority={true}
                width={65}
                height={24}
              />
            </StyledMobileLogo>
          </div>

          <div className="d-flex flex-row align-items-center justify-content-between">
            <StyledDesktopLogo href="/" prefetch={false}>
              <Image
                src={getLocalAssetFromCDN('/assets/logos/main-logo.svg')}
                alt="Elite Competitions Logo"
                priority={true}
                width={114}
                height={42}
              />
            </StyledDesktopLogo>

            <StyledMainMenu
              className="main-menu"
              onClick={e => {
                if (e.currentTarget.tagName === 'NAV') {
                  closeMenu()
                }
              }}
            >
              <StyledMainMenuList className="d-flex align-items-lg-center flex-column flex-lg-row justify-content-start">
                <li className="d-lg-none d-flex justify-content-between align-items-center">
                  <Image
                    src={getLocalAssetFromCDN('/assets/logos/main-logo.svg')}
                    alt="Elite Competitions Logo"
                    priority={true}
                    width={65}
                    height={24}
                  />

                  <StyledNavbarButton type="button" onClick={closeMenu}>
                    <CloseIcon width="32" height="32" viewBox="0 0 32 32" />
                  </StyledNavbarButton>
                </li>

                <StyledScrollContainer>
                  {isLoggedIn && notificationsCount > 0 && (
                    <li className="d-lg-none">
                      <Link
                        href={`${RoutesEnum.MY_ACCOUNT}${MyAccountRoutesEnum.MESSAGES}`}
                        prefetch={false}
                      >
                        <Stack direction="row" width="100%" paddingRight="16px">
                          <Stack
                            direction="row"
                            width="100%"
                            borderRadius="8px"
                            gap="8px"
                            padding="4px 8px"
                            justifyContent="flex-start"
                            alignItems="center"
                            bgcolor="var(--PrimaryVariant)"
                          >
                            <StyledBadge
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right'
                              }}
                              badgeContent={notificationsCount}
                              showZero={false}
                            >
                              <Typography
                                fontVariant="body-1"
                                weight="medium"
                                color="var(--Neutral1)"
                              >
                                <i className="fa-light fa-bell" />
                              </Typography>
                            </StyledBadge>

                            <Typography
                              fontVariant="heading-4"
                              weight="medium"
                              color="var(--Neutral1)"
                            >
                              New notification
                            </Typography>
                          </Stack>
                        </Stack>
                      </Link>
                    </li>
                  )}

                  <li className="d-lg-none">
                    <Link href="/" prefetch={false}>
                      Home
                    </Link>
                  </li>

                  {isLoggedIn && (
                    <li>
                      <Link
                        href={`${RoutesEnum.MY_ACCOUNT}${MyAccountRoutesEnum.TICKETS}`}
                        prefetch={false}
                      >
                        <span className="d-block d-lg-none">My Account</span>

                        <StyledBadge
                          className="d-none d-lg-block"
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right'
                          }}
                          badgeContent={notificationsCount}
                          showZero={false}
                        >
                          My Account
                        </StyledBadge>
                      </Link>
                    </li>
                  )}

                  <li>
                    <a className="d-none d-lg-inline-block pointer-none">
                      Competitions <i className="text-white fa fa-angle-down" />
                    </a>

                    <ul>
                      <li>
                        <Link
                          href={RoutesEnum.ACTIVE_COMPETITIONS}
                          prefetch={false}
                        >
                          Active Competitions
                        </Link>
                      </li>

                      <li>
                        <Link
                          href={RoutesEnum.INSTAWIN_COMPETITIONS}
                          prefetch={false}
                        >
                          InstaWin Competitions
                        </Link>
                      </li>

                      <li>
                        <Link
                          href={RoutesEnum.PENNY_COMPETITIONS}
                          prefetch={false}
                        >
                          Penny Competitions
                        </Link>
                      </li>

                      <li>
                        <Link
                          href={`/category/${CompetitionCategorySlugEnum.CAR_COMPETITIONS}`}
                          prefetch={false}
                        >
                          Car Competitions
                        </Link>
                      </li>

                      <li>
                        <Link
                          href={`/category/${CompetitionCategorySlugEnum.CASH_COMPETITIONS}`}
                          prefetch={false}
                        >
                          Cash Competitions
                        </Link>
                      </li>

                      <li>
                        <Link
                          href={`/category/${CompetitionCategorySlugEnum.WATCH_COMPETITIONS}`}
                          prefetch={false}
                        >
                          Watch Competitions
                        </Link>
                      </li>

                      <li>
                        <Link href={RoutesEnum.LIVE_DRAWS} prefetch={false}>
                          Live Draws
                        </Link>
                      </li>

                      <li>
                        <Link href="/winners-podium" prefetch={false}>
                          Winner Podium
                        </Link>
                      </li>
                    </ul>
                  </li>

                  {promoPageConfig.isActive && (
                    <li>
                      <Link
                        href={
                          promoPageConfig.url || RoutesEnum.MILLION_POUND_HOUSE
                        }
                        prefetch={false}
                      >
                        <StyledLandingItem>
                          {promoPageConfig.title}
                        </StyledLandingItem>
                      </Link>
                    </li>
                  )}

                  <li>
                    <Link href="/work-with-us" prefetch={false}>
                      <StyledWorkWithUsItem>Work with us</StyledWorkWithUsItem>
                    </Link>
                  </li>

                  <li>
                    <a className="d-none d-lg-inline-block pointer-none">
                      More <i className="text-white fa fa-angle-down" />
                    </a>

                    <ul>
                      <li>
                        <Link href="/how-it-works" prefetch={false}>
                          How it works
                        </Link>
                      </li>

                      <li>
                        <Link href={RoutesEnum.ABOUT_US} prefetch={false}>
                          About Us
                        </Link>
                      </li>

                      <li>
                        <Link href="/blog" prefetch={false}>
                          Blog
                        </Link>
                      </li>

                      <li>
                        <Link href="/faqs" prefetch={false}>
                          FAQS
                        </Link>
                      </li>
                    </ul>
                  </li>
                </StyledScrollContainer>

                {!isLoggedIn && (
                  <StyledMainMenuButtonSection>
                    <Link href="/login" prefetch={false}>
                      <StyledButton
                        size="large"
                        variant="secondary"
                        width="100%"
                      >
                        Log in
                      </StyledButton>
                    </Link>

                    <Link href="/register" prefetch={false}>
                      <StyledButton size="large" variant="primary" width="100%">
                        Create account
                      </StyledButton>
                    </Link>
                  </StyledMainMenuButtonSection>
                )}
              </StyledMainMenuList>

              {isLoggedIn && (
                <>
                  <StyledNavbarButton
                    type="button"
                    isActive={isWalletModalOpen}
                    className="d-none d-lg-flex flex-row"
                    onClick={openWalletModal}
                  >
                    <WalletIcon width="24" height="24" viewBox="0 0 24 24" />

                    {isWalletBalanceLoading ? (
                      <span>
                        Syncing... <i className="fa mr-1 fa-spinner fa-spin" />
                      </span>
                    ) : (
                      <span>£{numericWithComma(walletBalanceInternal)}</span>
                    )}
                  </StyledNavbarButton>

                  <StyledNavbarButton
                    type="button"
                    isActive={isOpenBasket}
                    isDisabled={isDisabledBasketButton}
                    className="d-none d-lg-flex flex-row cart-nav-link"
                    onClick={onToggleBasket}
                  >
                    <StyledBadge
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                      }}
                      max={1000}
                      badgeContent={totalTicketsCount}
                    >
                      <CartIcon width="24" height="24" viewBox="-3 0 24 24" />
                    </StyledBadge>
                  </StyledNavbarButton>
                </>
              )}
            </StyledMainMenu>

            {isLoggedIn && (
              <div className="d-flex align-items-center justify-content-end d-lg-none">
                <StyledNavbarButton
                  type="button"
                  isActive={isWalletModalOpen}
                  className="d-flex flex-row mr-3"
                  onClick={openWalletModal}
                >
                  <WalletIcon width="24" height="24" viewBox="0 0 24 24" />

                  {isWalletBalanceLoading ? (
                    <span>
                      Syncing... <i className="fa mr-1 fa-spinner fa-spin" />
                    </span>
                  ) : (
                    <span className="ml-1">
                      £{numericWithComma(walletBalanceInternal)}
                    </span>
                  )}
                </StyledNavbarButton>

                <StyledNavbarButton
                  type="button"
                  className="cart-nav-link"
                  isActive={isOpenBasket}
                  isDisabled={isDisabledBasketButton}
                  onClick={onToggleBasket}
                >
                  <StyledBadge
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right'
                    }}
                    max={1000}
                    badgeContent={totalTicketsCount}
                  >
                    <CartIcon width="24" height="24" viewBox="-3 0 24 24" />
                  </StyledBadge>
                </StyledNavbarButton>
              </div>
            )}

            {isLoggedIn && (
              <Basket
                show={isOpenBasket}
                isDisabled={isDisabledBasket}
                onClose={handleCloseBasket}
              />
            )}
          </div>
        </StyledHeader>

        <WalletModal
          isOpen={isWalletModalOpen}
          onClose={() => {
            setIsWalletModalOpen(false)
          }}
          walletMoneyBackPercentage={walletMoneyBackPercentage}
          walletAmount={walletBalanceInternal}
          isWalletAmountLoading={isWalletBalanceLoading}
        />
      </Container>

      <BannerPromotion showApplicationBanner={showApplicationBanner} />
    </StyledNavbar>
  )
})

export default Navbar
