import { isEqual, isNil } from 'lodash-es'

import { makeObservable, observable, action } from 'mobx'

import { DateTime } from 'luxon'

import {
  child,
  get,
  getDatabase,
  onChildChanged,
  ref,
  set
} from 'firebase/database'

import * as Sentry from '@sentry/nextjs'

import {
  type UserReservedTicketsForCompetitionDTO,
  TicketsService
} from '@elitecompetitions/client-api'

import { getFirebaseApp } from '@utils/Firebase'

import { authStoreFactory } from './Auth'

const registerUsersReservedTicketsListener = async (
  userId: number,
  changeTicketHandler: () => void
) => {
  const UserReservedTicketsRef = child(
    ref(getDatabase(getFirebaseApp())),
    `UsersReservedTickets/${userId}`
  )
  const snapshot = await get(UserReservedTicketsRef)

  if (!snapshot.exists()) {
    await set(UserReservedTicketsRef, { id: userId })
  }

  changeTicketHandler()

  const ticketsChangedUnsubscribe = onChildChanged(
    UserReservedTicketsRef,
    changeTicketHandler
  )

  return () => {
    ticketsChangedUnsubscribe()
  }
}

export class TicketsStore {
  timerTickId: number | undefined

  cartClearTimerStepInMS = 1000

  private unsubscribeReservedTicketsListener: (() => void) | undefined =
    undefined

  totalTicketsCount: number = 0

  totalTicketsPrice: number = 0

  roundUpTicketsCount: number = 0

  cartTicketsOverview: UserReservedTicketsForCompetitionDTO[] = []

  timeRemainingInSec: number | null = null

  isSyncingTickets = false

  constructor() {
    makeObservable(this, {
      totalTicketsCount: observable,
      totalTicketsPrice: observable,
      roundUpTicketsCount: observable,
      cartTicketsOverview: observable,
      timeRemainingInSec: observable,
      isSyncingTickets: observable,

      timerCountdown: action,
      setTimer: action,
      setTicketsData: action,
      clear: action,
      setIsSyncingTickets: action
    })

    this.setIsSyncingTickets = this.setIsSyncingTickets.bind(this)
    this.setTicketsData = this.setTicketsData.bind(this)
  }

  timerCountdown() {
    if (this.timeRemainingInSec > 0) {
      this.setTimer(
        this.timeRemainingInSec - this.cartClearTimerStepInMS / 1000
      )
    }
  }

  setTimer(timeInSec: number) {
    this.timeRemainingInSec = timeInSec < 0 ? 0 : timeInSec
  }

  setTicketsData({
    totalTicketsPrice = 0,
    totalTicketsCount = 0,
    roundUpTicketsCount = 0,
    cartTicketsOverview = []
  }: {
    totalTicketsPrice: number
    totalTicketsCount: number
    roundUpTicketsCount: number
    cartTicketsOverview: UserReservedTicketsForCompetitionDTO[]
  }) {
    if (!isEqual(this.totalTicketsPrice, totalTicketsPrice)) {
      this.totalTicketsPrice = totalTicketsPrice
    }

    if (!isEqual(this.totalTicketsCount, totalTicketsCount)) {
      this.totalTicketsCount = totalTicketsCount
    }

    if (!isEqual(this.roundUpTicketsCount, roundUpTicketsCount)) {
      this.roundUpTicketsCount = roundUpTicketsCount
    }

    if (!isEqual(this.cartTicketsOverview, cartTicketsOverview)) {
      this.cartTicketsOverview = cartTicketsOverview
    }
  }

  clear() {
    this.setTicketsData({
      totalTicketsCount: 0,
      totalTicketsPrice: 0,
      roundUpTicketsCount: 0,
      cartTicketsOverview: []
    })
  }

  setIsSyncingTickets(isSyncingTickets: boolean) {
    this.isSyncingTickets = isSyncingTickets
  }

  async attachUserReservedTicketListeners() {
    this.unsubscribeReservedTicketsListener =
      await registerUsersReservedTicketsListener(
        authStoreFactory().profile.id,
        async () => {
          try {
            await this.syncAll()
          } catch (error) {
            Sentry.captureException(error)
          }
        }
      )
  }

  async removeUserReservedTicketListeners() {
    this.unsubscribeReservedTicketsListener?.()
  }

  async syncAll() {
    this.setIsSyncingTickets(true)

    const cartTicketsOverviewResponse =
      await TicketsService.getUserReservedTicketsOverview()

    const { data: cartTicketsOverview = [], extra } =
      cartTicketsOverviewResponse

    const {
      cartClearingTime = null,
      roundUpTicketsCount = 0,
      totalTicketsCount = 0,
      totalTicketsPrice = 0
    } = extra

    this.setTicketsData({
      roundUpTicketsCount,
      cartTicketsOverview,
      totalTicketsCount,
      totalTicketsPrice
    })

    this.syncCartTimer(cartClearingTime)
  }

  syncCartTimer(cartClearingTime: string | null) {
    if (isNil(cartClearingTime)) {
      return
    }

    const now = DateTime.now()
    const cartClearingDateTime = DateTime.fromISO(cartClearingTime)

    const remainingTimeStampInSec = cartClearingDateTime.diff(
      now,
      'seconds'
    ).seconds

    this.setTimer(remainingTimeStampInSec)
    this.timerCountdown()

    if (this.timerTickId) {
      clearInterval(this.timerTickId)
    }

    this.setIsSyncingTickets(false)

    this.timerTickId = window.setInterval(() => {
      this.timerCountdown()
    }, this.cartClearTimerStepInMS)
  }
}

let ticketsStore: TicketsStore

export function ticketsStoreFactory() {
  if (!process.browser) {
    ticketsStore = new TicketsStore()
  }
  if (process.browser && !ticketsStore) {
    ticketsStore = new TicketsStore()
  }

  return ticketsStore
}

export default ticketsStore
