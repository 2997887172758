import { Box, css, styled } from '@mui/material'

import Odometer from '../Odometer'
import Typography from '../Typography'

export const StyledOdometer = styled(Odometer, {
  shouldForwardProp: propName =>
    propName !== 'showLabel' && propName !== 'width'
})<{
  showLabel?: boolean
}>`
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-family: 'Orbitron', sans-serif;
  font-weight: 600;

  ${({ showLabel = false }) => {
    if (showLabel) {
      return css`
        justify-content: left;
        text-align: left;
        width: 55%;
      `
    }

    return css`
      justify-content: center;
      text-align: center;
      width: 100%;
    `
  }}

  ${({
    theme: {
      breakpoints: {
        values: { xs, sm, md, lg }
      }
    }
  }) => {
    return css`
      @media (min-width: ${xs}px) {
        font-size: 20px;
        line-height: 26px;
      }

      @media (min-width: ${sm}px) {
        font-size: 22px;
        line-height: 30px;
      }

      @media (min-width: ${md}px) {
        font-size: 22px;
        line-height: 30px;
      }

      @media (min-width: ${lg}px) {
        font-size: 24px;
        line-height: 32px;
      }
    `
  }}
`

export const StyledTypography = styled(Typography)`
  text-shadow:
    -1px -1px 0 var(--Primary),
    1px -1px 0 var(--Primary),
    -1px 1px 0 var(--Primary),
    1px 1px 0 var(--Primary),
    0 -1px 0 var(--Primary),
    0 1px 0 var(--Primary),
    -1px 0 0 var(--Primary),
    1px 0 0 var(--Primary);
`

export const StyledJackpotDataBadgeContainer = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  box-shadow: inset 1px 1px 1px 0 rgba(255, 255, 255, 0.4);
  background: radial-gradient(
      107.08% 85.59% at 86.3% 87.5%,
      rgba(0, 0, 0, 0.23) 0%,
      rgba(0, 0, 0, 0) 86.18%
    ),
    radial-gradient(
      83.94% 83.94% at 26.39% 20.83%,
      rgba(255, 255, 255, 0.24) 0%,
      rgba(255, 255, 255, 0) 69.79%,
      rgba(255, 255, 255, 0) 100%
    ),
    linear-gradient(92deg, var(--SecondaryVariant) 7.69%, var(--Primary) 93.35%);
`
