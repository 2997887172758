import { Box, styled } from '@mui/material'

export const StyledCard = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  background: var(--Neutral8);
  padding: 24px 16px;
`
