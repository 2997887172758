import { useCallback, useEffect } from 'react'

import { useRouter } from 'next/router'

import { observer } from 'mobx-react'

import { Stack } from '@mui/material'

import { MoreInstaWinCompetitions, Button, Typography } from '@lib/ui'

import { RoutesEnum } from '@enums'

import { useWalletBalance } from '@hooks/profile/useWalletBalance'

import { StyledCard } from './TransactionNotFound.styled'
import { type ITransactionNotFoundProps } from './TransactionNotFound.types'

const TransactionNotFound = (props: ITransactionNotFoundProps) => {
  const { analytics, pageType } = props

  const router = useRouter()

  const { refetchWalletBalance } = useWalletBalance()

  const handleOpenHomePage = useCallback(async () => {
    await router.push(RoutesEnum.HOME)
  }, [router])

  const handleCompetitionsPage = useCallback(async () => {
    await router.push(RoutesEnum.ACTIVE_COMPETITIONS)
  }, [router])

  useEffect(() => {
    ;(async () => {
      await refetchWalletBalance()
    })()
  }, [])

  return (
    <Stack
      direction="column"
      gap={{
        lg: '100px',
        md: '90px',
        sm: '90px',
        xs: '80px'
      }}
    >
      <StyledCard>
        <Stack direction="column" alignItems="center" gap="16px">
          <Typography fontVariant="heading-3" weight="semibold">
            This transaction is not found
          </Typography>

          <Stack
            direction="row"
            gap="16px"
            justifyContent="center"
            alignItems="center"
          >
            <Button
              type="button"
              size="large"
              variant="secondary"
              onClick={handleOpenHomePage}
            >
              Home
            </Button>

            <Button
              type="button"
              size="large"
              variant="primary"
              onClick={handleCompetitionsPage}
            >
              Competitions
            </Button>
          </Stack>
        </Stack>
      </StyledCard>

      <MoreInstaWinCompetitions
        heading="InstaWin Competitions"
        pageType={pageType}
        analytics={analytics}
      />
    </Stack>
  )
}

export default observer(TransactionNotFound)
