import { useCallback, useEffect, useState } from 'react'

import { Frames } from 'frames-react'

import config from '@services/config'

import { Loader, Typography } from '@lib/ui'

import { useIsMounted } from '@hooks/useIsMounted'

import { type ICKOFormProps, CKOScriptStateEnum } from './CKOForm.types'
import { StyledCvv, StyledCardNumber, StyledExpiryDate } from './CKOForm.styled'

const CKOForm = (props: ICKOFormProps) => {
  const { onChangeTokenCKO, onReadyCKO } = props

  const [isReadyCKO, setIsReadyCKO] = useState(false)

  const [ckoScriptState, setCKOScriptState] = useState<CKOScriptStateEnum>(
    CKOScriptStateEnum.LOADING
  )

  const isMounted = useIsMounted()

  const loadFramesScript = useCallback(() => {
    const script = document.getElementById('checkout-frames-script')

    if (!script) {
      const script = document.createElement('script')

      script.src = 'https://cdn.checkout.com/js/framesv2.min.js'
      script.id = 'checkout-frames-script'
      script.onload = () => {
        setCKOScriptState(CKOScriptStateEnum.LOADED)
      }
      script.onerror = () => {
        setCKOScriptState(CKOScriptStateEnum.ERROR)
      }

      document.body.appendChild(script)
    } else {
      setCKOScriptState(CKOScriptStateEnum.LOADED)
    }
  }, [])

  useEffect(() => {
    onReadyCKO(isReadyCKO)
  }, [isReadyCKO])

  useEffect(() => {
    loadFramesScript()
  }, [])

  /**
   * If we don't wait for the component to be mounted before rendering the iframes, it sometimes fails
   * @link https://github.com/checkout/frames-react/issues/25
   * @link https://elitecompetitions.sentry.io/issues/5384230709
   **/
  if (!isMounted) {
    return null
  }

  if (ckoScriptState === CKOScriptStateEnum.LOADING) {
    return (
      <Loader isOpen={true}>
        <Typography
          fontVariant="heading-3"
          weight="semibold"
          textAlign="center"
          color="var(--Neutral1)"
        >
          Loading...
        </Typography>
      </Loader>
    )
  }

  if (ckoScriptState === CKOScriptStateEnum.ERROR) {
    return null
  }

  return (
    <Frames
      config={{
        debug: true,
        publicKey: config.cko.publicKey,
        localization: {
          cardNumberPlaceholder: 'Card number',
          expiryMonthPlaceholder: 'MM',
          expiryYearPlaceholder: 'YY',
          cvvPlaceholder: 'CVV'
        },
        style: {
          base: {
            color: '#ffffff'
          },
          placeholder: {
            base: {
              color: '#626262'
            }
          }
        }
      }}
      ready={() => {
        setIsReadyCKO(true)
      }}
      frameActivated={() => {}}
      frameFocus={() => {}}
      frameBlur={() => {}}
      frameValidationChanged={() => {}}
      paymentMethodChanged={() => {}}
      cardValidationChanged={() => {}}
      cardSubmitted={() => {}}
      cardTokenized={event => {
        const { token } = event

        onChangeTokenCKO(token)
      }}
      cardTokenizationFailed={() => {}}
    >
      <StyledCardNumber />

      <StyledExpiryDate />

      <StyledCvv />
    </Frames>
  )
}

export default CKOForm
