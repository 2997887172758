import {
  defaultTo,
  floor,
  isArray,
  isFunction,
  isNil,
  isObject,
  times
} from 'lodash-es'

import { CurrencyTypeEnum } from '@enums'

import { isBrowser } from '@helpers/isBrowser'

import {
  type CompetitionBaseDto,
  type CompetitionDetailsDTO,
  type UserReservedTicketsForCompetitionDTO,
  type UserTransactionOverviewDTO
} from '@elitecompetitions/client-api'

import { getAppPlatform } from '.'

const paqPush = <T>(params: T) => {
  if (!isBrowser()) {
    return
  }

  try {
    window._paq = window._paq || []

    if (!isArray(params)) {
      return
    }

    if (!isFunction(window._paq.push)) {
      return
    }

    if (params[0] === 'trackEvent') {
      params[1] =
        typeof params[1] === 'string' && params[1].trim() !== ''
          ? params[1].replace(/\s+/g, '_')
          : 'default_category'
      params[2] =
        typeof params[2] === 'string' && params[2].trim() !== ''
          ? params[2].replace(/\s+/g, '_')
          : 'default_action'

      if (
        params[3] &&
        typeof params[3] === 'object' &&
        !Array.isArray(params[3])
      ) {
        params[3] = Object.entries(params[3]).reduce(
          (newPayload, [key, value]) => {
            if (typeof key === 'string' && value) {
              newPayload[key.replace(/\s+/g, '_')] = value
            }
            return newPayload
          },
          {}
        )
        params[3] = JSON.stringify(params[3])
      }
    }

    window._paq.push(params)
  } catch (error) {
    console.error(error)
  }
}

let cartItems = {}

export const matomo = {
  view_product: (item: CompetitionDetailsDTO) => {
    try {
      const price = item.ticketPrice
      const productSKU = item.id
      const productName = item.name
      const categoryName = item.hasInstantPrizes
        ? 'insta-win'
        : 'regular-competition'
      const priceUpdated = item.isOnSale ? item.saleTicketPrice : price

      paqPush([
        'setEcommerceView',
        productSKU,
        productName,
        categoryName,
        priceUpdated
      ])

      paqPush(['trackPageView'])
    } catch (error) {
      console.error(error)
    }
  },

  add_to_cart: (
    item: CompetitionBaseDto,
    {
      overRideValue = false,
      val = 0,
      quantity = 1
    }: Partial<{
      overRideValue: boolean
      val: number
      quantity: number
    }>
  ) => {
    try {
      const price = item.ticketPrice
      const value = overRideValue
        ? val
        : item.isOnSale
          ? item.saleTicketPrice
          : item.ticketPrice

      const productSKU = item.id
      const productName = item.name
      const categoryName = item.hasInstantPrizes
        ? 'insta-win'
        : 'regular-competition'

      if (cartItems[productSKU]) {
        cartItems[productSKU].quantity += quantity
      } else {
        cartItems[productSKU] = {
          name: productName,
          category: categoryName,
          price: price,
          quantity: quantity
        }
      }

      paqPush([
        'addEcommerceItem',
        productSKU,
        productName,
        categoryName,
        price,
        cartItems[productSKU].quantity
      ])

      paqPush([
        'trackEcommerceCartUpdate',
        value * cartItems[productSKU].quantity
      ])
    } catch (error) {
      console.error('utils.matomo.add_to_cart', error)
    }
  },

  remove_from_cart: (competitionId: number) => {
    try {
      paqPush(['removeEcommerceItem', competitionId])
    } catch (error) {
      console.error(error)
    }
  },

  begin_checkout: ({
    cartTicketsOverviewByCompetition,
    value,
    appliedCoupon
  }: {
    cartTicketsOverviewByCompetition: {
      competition: CompetitionBaseDto
      ticketsOverview: UserReservedTicketsForCompetitionDTO
    }[]
    appliedCoupon: string | null
    value: number
  }) => {
    try {
      paqPush([
        'trackEvent',
        'ecommerce',
        'begin_checkout',
        JSON.stringify({
          currency: CurrencyTypeEnum.GBP,
          platform: getAppPlatform(),
          value,
          coupon: defaultTo(appliedCoupon, ''),
          items: cartTicketsOverviewByCompetition.map(item => {
            const { competition, ticketsOverview } = item
            const { ticketCount, ticketPrice, ticketOriginalPrice } =
              ticketsOverview
            const { id, name } = competition

            return times(ticketCount, () => {
              return {
                item_id: id,
                item_name: name,
                price: ticketPrice,
                discount: floor(ticketOriginalPrice - ticketPrice, 2)
              }
            })
          })
        })
      ])
    } catch (error) {
      console.error(error)
    }
  },

  add_payment_info: ({
    cartTicketsOverviewByCompetition,
    value,
    appliedCoupon,
    paymentMethod
  }: {
    cartTicketsOverviewByCompetition: {
      competition: CompetitionBaseDto
      ticketsOverview: UserReservedTicketsForCompetitionDTO
    }[]
    value: number
    appliedCoupon: string | null
    paymentMethod: string
  }) => {
    try {
      paqPush([
        'trackEvent',
        'ecommerce',
        'add_payment_info',
        JSON.stringify({
          payment_type: paymentMethod,
          currency: CurrencyTypeEnum.GBP,
          platform: getAppPlatform(),
          value,
          coupon: defaultTo(appliedCoupon, ''),
          items: cartTicketsOverviewByCompetition
            .map(item => {
              const { competition, ticketsOverview } = item
              const { ticketCount, ticketPrice, ticketOriginalPrice } =
                ticketsOverview
              const { id, name } = competition

              return times(ticketCount, () => {
                return {
                  item_id: id,
                  item_name: name,
                  price: ticketPrice,
                  discount: floor(ticketOriginalPrice - ticketPrice, 2)
                }
              })
            })
            .flat()
        })
      ])
    } catch (error) {
      console.error(error)
    }
  },

  purchase: ({
    activeCompetitions,
    transactionOverview,
    amount,
    transactionID
  }: {
    activeCompetitions: CompetitionBaseDto[]
    transactionOverview: UserTransactionOverviewDTO
    amount: number
    transactionID: string
  }) => {
    try {
      const { tickets: transactionTicketsOverview = [] } = transactionOverview

      transactionTicketsOverview.forEach(({ competitionId, count }) => {
        const competition = activeCompetitions.find(({ id }) => {
          return id === competitionId
        })

        if (!isNil(competition)) {
          const { id, name, ticketPrice } = competition

          paqPush(['addEcommerceItem', id, name, '', ticketPrice, count])
        }
      })

      paqPush(['trackEcommerceOrder', transactionID, amount])
    } catch (error) {
      console.error(error)
    }
  },

  customEvent: (eventName, payload) => {
    try {
      payload = isObject(payload) ? JSON.stringify(payload) : payload

      paqPush(['trackEvent', 'default_category', eventName, payload])
    } catch (error) {
      console.error(error)
    }
  }
}
