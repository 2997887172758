import { type MouseEvent, useCallback } from 'react'

import { type Swiper } from 'swiper/types'

export function useSwiperCarousel(props: {
  isNext: boolean
  isPrev: boolean
  swiper: Swiper
}) {
  const { isNext, isPrev, swiper } = props

  const handleCarouselClick = useCallback(
    async (event: MouseEvent) => {
      /**
       * @description It's a hack cuz slideToClickedSlide (slideTo as well) doesn't work properly
       * @link https://github.com/nolimits4web/swiper/issues/7148
       */
      if (isNext) {
        swiper.slideNext()

        event.preventDefault()
      } else if (isPrev) {
        swiper.slidePrev()

        event.preventDefault()
      }
    },
    [isNext, isPrev, swiper]
  )

  return {
    handleCarouselClick
  }
}
