import { styled } from '@mui/material'

import { AmexIcon, MastercardIcon, VisaIcon } from '@lib/icons'

export const StyledVisaIcon = styled(VisaIcon)`
  width: 34px;
`

export const StyledMastercardIcon = styled(MastercardIcon)`
  width: 35px;
`

export const StyledAmexIcon = styled(AmexIcon)`
  width: 35px;
`
