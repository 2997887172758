import { Stack } from '@mui/material'

import { Skeleton, Typography } from '@lib/ui'

import { numericWithComma } from '@helpers'

import { type ICartTotalAmountProps } from './CartTotalAmount.types'

const CartTotalAmount = (props: ICartTotalAmountProps) => {
  const { isLoading = false, finalPrice, ...otherProps } = props

  if (isLoading) {
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <Skeleton mode="dark" animation="wave" width="60%" height={25} />

        <Skeleton mode="dark" animation="wave" width="30%" height={25} />
      </Stack>
    )
  }

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      {...otherProps}
    >
      <Typography
        fontVariant="heading-4"
        weight="semibold"
        color="var(--Neutral1)"
      >
        Total amount
      </Typography>

      <Typography
        fontVariant="heading-4"
        weight="semibold"
        color="var(--Neutral1)"
      >
        {`£${numericWithComma(finalPrice)}`}
      </Typography>
    </Stack>
  )
}

export default CartTotalAmount
