import Link from 'next/link'

import { useSwiper } from 'swiper/react'

import { CdnImage } from '@lib/ui'

import {
  StyledMainCarouselContent,
  StyledMainCarouselImage,
  StyledMainInfoTitle
} from '@lib/ui/MainCarousel/MainCarousel.styled'

import { getLocalAssetFromCDN } from '@helpers'

import { useJackpotChangedListener } from '@hooks/competition/useJackpotChangedListener'
import { useSwiperCarousel } from '@hooks/useCarousel'

import HomeCarouselFeatureBadges from '../HomeCarouselFeatureBadges'
import HomeCarouselTicketPriceBadge from '../HomeCarouselTicketPriceBadge'

import { type IHomeCarouselItemProps } from './HomeCarouselItem.types'
import {
  StyledBadgeContainer,
  StyledBadgeStack,
  StyledAkaButton,
  StyledJackpotAmount,
  StyledCharityJackpotAmount
} from './HomeCarouselItem.styled'
import { StyledOdometer } from '@lib/ui/JackpotDataBadge/JackpotDataBadge.styled'

const getImagePlaceholder = (imagePlaceholder: string) => {
  return imagePlaceholder.length ? 'blur' : 'empty'
}

const HomeCarouselItem = (props: IHomeCarouselItemProps) => {
  const { competition, isNext, isPrev } = props

  const swiper = useSwiper()

  const { handleCarouselClick } = useSwiperCarousel({
    isPrev,
    isNext,
    swiper
  })

  const {
    id,
    cdnImage,
    slugUrl,
    hasJackpot,
    firebaseId,
    isFree,
    name,
    isOnSale,
    ticketPrice,
    saleTicketPrice,
    imagePlaceholder,
    jackpotImage
  } = competition

  const link = `/competitions/${slugUrl}`

  const { jackpotAmount, charityJackpotAmount } = useJackpotChangedListener({
    hasJackpot,
    id,
    firebaseId
  })

  return (
    <Link href={link} passHref={true} onClick={handleCarouselClick}>
      <StyledMainCarouselImage>
        <div className="position-relative">
          <CdnImage
            alt={name}
            image={jackpotImage ?? cdnImage}
            placeholder={getImagePlaceholder(imagePlaceholder)}
            fallbackSrc={getLocalAssetFromCDN(
              '/assets/img/competition-default-image.webp'
            )}
          />
          {jackpotImage && (
            <>
              <StyledJackpotAmount>
                <StyledOdometer
                  showLabel={false}
                  enableScrollSpy={true}
                  scrollSpyOnce={true}
                  preserveValue={true}
                  end={jackpotAmount}
                  duration={3}
                  separator=","
                  decimals={2}
                  decimal="."
                  prefix="£"
                  style={{
                    fontSize: 'inherit'
                  }}
                />
              </StyledJackpotAmount>

              <StyledCharityJackpotAmount>
                <StyledOdometer
                  showLabel={false}
                  enableScrollSpy={true}
                  scrollSpyOnce={true}
                  preserveValue={true}
                  end={charityJackpotAmount}
                  duration={3}
                  separator=","
                  decimals={2}
                  decimal="."
                  prefix="£"
                  style={{
                    fontSize: 'inherit'
                  }}
                />
              </StyledCharityJackpotAmount>
            </>
          )}
        </div>

        <StyledAkaButton>Enter</StyledAkaButton>

        <StyledBadgeContainer>
          <StyledBadgeStack direction="row" justifyContent="flex-end" gap="8px">
            <HomeCarouselFeatureBadges competition={competition} />

            {!isFree && (
              <HomeCarouselTicketPriceBadge
                isOnSale={isOnSale}
                ticketPrice={ticketPrice}
                saleTicketPrice={saleTicketPrice}
              />
            )}
          </StyledBadgeStack>
        </StyledBadgeContainer>
      </StyledMainCarouselImage>

      <StyledMainCarouselContent>
        <StyledMainInfoTitle>{name}</StyledMainInfoTitle>
      </StyledMainCarouselContent>
    </Link>
  )
}

export default HomeCarouselItem
